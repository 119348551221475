const white = "#FFFFFF";
const black = "#000000";
const grey0 = "#8E8E93";
const grey1 = "#AEAEB2";
const grey2 = "#C7C7CC";
const grey3 = "#D1D1D6";
const grey4 = "#E5E5EA";
const grey5 = "#F2F2F7";
const grey6 = "#5c5c5c";
const messageGrey = "#545456";
// const messageGrey = "#414142";
const greyBackground = "#252525";
// const mediumGreyBackground = "#1D1D1D";
const darkGreyBackground = "#1D1D1D";
const darkerGreyBackground = "#1A1A1A";

const transparent = "transparent";
const green = "#4BB543";
const voteGreen = "#00D387";
const brightGreen = "#05FFA5";
const yellow = "#FFBE0B";
const orange = "#FB5607";
const red = "#FD2B3B";
const brightRed = "#FF073A";
const pink = "#FF006E";
const purple = "#8338EC";
const blue = "#3A86FF";
const softBlue = "#2879BC";

const lightGold = "#FFDD28";
const darkGold = "#6F4900";

const imessageGreen = "#4CD964";
const snapchatYellow = "#FFFC01";
const facebookBlue = "#1877f2";
const twitterBlue = "#51A7E8";
const instagramGradient = [
  "#ffdd55",
  "#ffcb4d",
  "#ffb84b",
  "#ffa54e",
  "#ff9255",
  "#ff7e5f",
  "#ff6b6a",
  "#ff5977",
  "#ff4a84",
  "#f33e91",
  "#e0379f",
  "#c837ab",
];
const hexToOpacity = (opacityHex: string) => {
  return parseInt(opacityHex, 16) / 255;
};
const opacityToHex = (opacity: number) => {
  return (opacity * 255).toString(16).substr(0, 2);
};
const opacity = (color?: string, opacity?: number) => {
  return `${color ?? "#FFFFFF"}${opacityToHex(opacity ?? 1)}`;
};

//GRADIENTS
const gradientYellowPink: string[] = [
  "#ffbe0b",
  "#ffb10d",
  "#ffa416",
  "#ff9620",
  "#ff882a",
  "#ff7933",
  "#ff6a3d",
  "#ff5a47",
  "#ff4950",
  "#ff375a",
  "#ff2364",
  "#ff006e",
];

const gradientPinkYellow: string[] = [
  "#ff006e",
  "#ff2364",
  "#ff375a",
  "#ff4950",
  "#ff5a47",
  "#ff6a3d",
  "#ff7933",
  "#ff882a",
  "#ff9620",
  "#ffa416",
  "#ffb10d",
  "#ffbe0b",
];
const gradientYellowRed: string[] = [
  "#ffbe0b",
  "#ffb303",
  "#ffa802",
  "#ff9c05",
  "#ff910c",
  "#ff8414",
  "#ff781c",
  "#ff6b22",
  "#ff5d29",
  "#ff4f2f",
  "#ff3f35",
  "#fd2b3b",
];

const gradientRedYellow: string[] = [
  "#fd2b3b",
  "#ff3f35",
  "#ff4f2f",
  "#ff5d29",
  "#ff6b22",
  "#ff781c",
  "#ff8414",
  "#ff910c",
  "#ff9c05",
  "#ffa802",
  "#ffb303",
  "#ffbe0b",
];

const gradientRedPink: string[] = [
  "#fd2b3b",
  "#fe2740",
  "#ff2345",
  "#ff1f49",
  "#ff1b4e",
  "#ff1653",
  "#ff1257",
  "#ff0d5c",
  "#ff0860",
  "#ff0465",
  "#ff0269",
  "#ff006e",
];

const gradientOrangePink: string[] = [
  "#fb5607",
  "#fe4f19",
  "#ff4824",
  "#ff412e",
  "#ff3a37",
  "#ff333f",
  "#ff2b47",
  "#ff234f",
  "#ff1a57",
  "#ff115f",
  "#ff0866",
  "#ff006e",
];

const gradientPurpleOrange: string[] = [
  "#8338ec",
  "#ab1edc",
  "#c800c9",
  "#df00b5",
  "#f000a0",
  "#fd008b",
  "#ff0076",
  "#ff0062",
  "#ff004e",
  "#ff203a",
  "#ff4026",
  "#fb5607",
];

const gradientBlueOrange: string[] = [
  "#3a86ff",
  "#767dfa",
  "#9f71f1",
  "#c064e2",
  "#da54d0",
  "#ef44ba",
  "#ff2887",
  "#ff286c",
  "#ff3350",
  "#ff4433",
  "#fb5607",
];

const chatGradient: string[] = ["#ff7723", "#ff006e"];
const chatGradientPinkOrange: string[] = [
  "#ff006e",
  "#ff1667",
  "#ff2460",
  "#ff3059",
  "#ff3b52",
  "#ff444b",
  "#ff4e45",
  "#ff563e",
  "#ff5f38",
  "#ff6731",
  "#ff6f2a",
  "#ff7723",
];
const chatOrange = "#FE3F35";

const pinkColorArray: string[] = [
  "#e01584",
  "#e21585",
  "#e31586",
  "#e51687",
  "#e71688",
  "#e81689",
  "#ea168a",
  "#eb168b",
  "#ed168c",
  "#ef178d",
  "#f0178e",
  "#f2178f",
];

const metalicColorArray: string[] = [
  "#e1e4e8",
  "#d9dce1",
  "#d1d5da",
  "#c9cdd2",
  "#c1c6cb",
  "#b9bec4",
  "#b1b7bd",
  "#aab0b6",
  "#a2a8af",
  "#9aa1a9",
  "#939aa2",
  "#8b939b",
];

const colorPattern = [yellow, purple, blue, pink, red, yellow, pink, blue];

const altPink = "#e34a59";
const altPurple = "#984ca8";
const altYellow = "#e3be20";
const altBlue = "#597ea8";
const altOrange = "#e34a1e";

const altColorPattern = [
  altYellow,
  altPurple,
  altBlue,
  altPink,
  altOrange,
  altYellow,
  altPink,
  altBlue,
];

const gradientFourPointFadedEdges: string[] = [
  "#FFFFFF00",
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF00",
];

const logoLeftGradient: string[] = [
  "#ffe808",
  "#ffe606",
  "#ffe305",
  "#ffe103",
  "#ffde02",
  "#ffdc01",
  "#ffda01",
  "#ffd700",
  "#ffd500",
  "#ffd300",
  "#ffd000",
  "#ffce00",
];

const logoBottomGradient: string[] = [
  "#ffce00",
  "#ffc900",
  "#ffc500",
  "#ffc000",
  "#ffbb00",
  "#ffb600",
  "#ffb200",
  "#ffad00",
  "#ffa800",
  "#ffa400",
  "#ff9f00",
  "#ff9a00",
];
export {
  white,
  black,
  transparent,
  green,
  voteGreen,
  brightGreen,
  yellow,
  orange,
  red,
  brightRed,
  pink,
  purple,
  blue,
  softBlue,
  grey0,
  grey1,
  grey2,
  grey3,
  grey4,
  grey5,
  grey6,
  lightGold,
  darkGold,
  messageGrey,
  imessageGreen,
  snapchatYellow,
  facebookBlue,
  twitterBlue,
  instagramGradient,
  hexToOpacity,
  opacityToHex,
  opacity,
  greyBackground,
  darkerGreyBackground,
  // mediumGreyBackground,
  darkGreyBackground,
  gradientYellowPink,
  gradientPinkYellow,
  gradientYellowRed,
  gradientRedYellow,
  gradientRedPink,
  gradientOrangePink,
  gradientPurpleOrange,
  gradientBlueOrange,
  chatGradient,
  chatGradientPinkOrange,
  chatOrange,
  pinkColorArray,
  metalicColorArray,
  colorPattern,
  altPink,
  altPurple,
  altYellow,
  altBlue,
  altOrange,
  altColorPattern,
  gradientFourPointFadedEdges,
  logoLeftGradient,
  logoBottomGradient,
};
