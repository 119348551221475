import { combineReducers } from "redux";
import { userReducer } from "./user.reducer";
import { contentReducer } from "./content.reducer";

export const rootReducer = combineReducers<AppState>({
  user: userReducer,
  content: contentReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
