import { DISCOVER_FEED_ID } from "../../constants";
import { getVoteBitmojiStickerData } from "../../utilities/connect";

const initialState: ContentData = {
  songMap: {},
  userMap: {},
  playlists: {},
  playlistsMetaData: {},
  isBurstMode: true,
  currPlaylist: DISCOVER_FEED_ID,
  currIndex: 0,
  friendIDList: [],
  playlistSets: {},
  selectedSong: { index: 0, playlistID: DISCOVER_FEED_ID },
};

var imageCounter = 0;

export function contentReducer(
  state: ContentData = { ...initialState },
  action: ContentActionTypes,
): ContentData {
  function setSong(song: Song) {
    if (song?.exists === false) {
      return state;
    }

    if (song.ranks) {
      song.rankMap = { ...state.songMap[song.id]?.rankMap };
      song.ranks?.forEach((item: Rank) => {
        const { tag, rank } = item;
        if (song.rankMap) song.rankMap[tag] = rank;
      });
    }

    if (!state.songMap[song.id]?.stickerData) {
      song.stickerData = getVoteBitmojiStickerData();
    }

    // imageCounter += 1;
    // song.baseImage = `https://picsum.photos/${0}/10`;

    state.songMap[song.id] = { ...state.songMap[song.id], ...song };
    if (song.id === state.currSong?.id) state.currSong = state.songMap[song.id];
    //console.re.log("SET_SONG", song, state.songMap[song.id]);
    return state;
  }

  function setUser(user: User) {
    state.userMap[user.id] = user;
    return state;
  }

  function setActiveSong() {
    const currentSong =
      state.songMap[
        state.playlists[state.currPlaylist]?.[state.currIndex] ?? ""
      ];
    if (state.currSong) state.currSong.isActive = undefined;
    currentSong.isActive = state.currPlaylist;
    state.currSong = currentSong;
    return state;
  }

  function setPlaylistMetadata(
    playlistID: string,
    playlistData: PlaylistMetadata,
  ) {
    const playlistMetadata = state.playlistsMetaData[playlistID];
    state.playlistsMetaData[playlistID] = {
      ...playlistMetadata,
      ...playlistData,
    };
    state.playlistsMetaData = { ...state.playlistsMetaData };
    return state;
  }

  switch (action.type) {
    case "PUSH_SONGS":
      const {
        playlistID: playlistIDPushSong,
        songList: songListPushSong,
        userList: userListPushSong,
        options: optionsPushSong,
      } = action as PushSongAction;
      const {
        refresh,
        prepend,
        remove,
        playlistData: playlistDataPushSong,
      } = optionsPushSong ?? {};
      console.log(
        "load play",
        playlistIDPushSong,
        songListPushSong,
        userListPushSong,
      );
      const currPlaylist = state.playlists?.[playlistIDPushSong];

      if (!currPlaylist || refresh) {
        state.playlists[playlistIDPushSong] = [];
      } else {
        state.playlists[playlistIDPushSong] = [...currPlaylist];
      }

      var thisPlaylist = state.playlists[playlistIDPushSong];
      songListPushSong.forEach((song) => {
        if (!song.id) return;
        setSong(song);
        if (prepend || remove) {
          state.playlists[playlistIDPushSong] = thisPlaylist?.filter(
            (id) => id !== song.id,
          );
          if (prepend) state.playlists[playlistIDPushSong]?.unshift(song.id);
        } else state.playlists[playlistIDPushSong]?.push(song.id);
      });

      userListPushSong.forEach((user) => {
        setUser(user);
      });

      if (playlistDataPushSong) {
        setPlaylistMetadata(playlistIDPushSong, playlistDataPushSong);
      }
      state.songMap = { ...state.songMap };
      state.playlists = { ...state.playlists };
      return { ...state };
    case "SET_SONGS":
      const { songList: songListSetSong } = action as SetSongAction;
      songListSetSong?.forEach((song) => {
        setSong(song);
      });
      return { ...state };
    case "SET_USERS":
      const { users: userListSetUser } = action as SetUserAction;
      userListSetUser?.forEach((user) => {
        setUser(user);
      });
      //console.re.log("SET_USERS", userListSetUser, state.userMap);
      return { ...state };
    case "SET_PLAYLISTS_METADATA":
      const { setID, playlistIDs, playlistsMetadata } =
        action as SetPlaylistsMetadataAction;
      if (setID && playlistIDs) state.playlistSets[setID] = playlistIDs;
      state.playlistsMetaData = {
        ...state.playlistsMetaData,
        ...playlistsMetadata,
      };
      return { ...state };
    case "SET_PLAYLIST":
      const {
        playlistID: playlistIDSetPlaylists,
        songIDList: songIDListSetPlaylists,
        options: optionsSetPlaylists,
      } = action as SetPlaylistAction;
      const { playlistData: playlistDataSetPlaylist } =
        optionsSetPlaylists ?? {};
      const playlists = { ...state.playlists };
      playlists[playlistIDSetPlaylists] = songIDListSetPlaylists;
      state.playlists = playlists;
      if (playlistDataSetPlaylist) {
        setPlaylistMetadata(playlistIDSetPlaylists, playlistDataSetPlaylist);
      }

      return { ...state };
    case "SET_CURR_PLAYLIST":
      const { playlistID: playlistIDSetCurrPlaylist } =
        action as SetCurrPlaylistAction;
      state.currPlaylist = playlistIDSetCurrPlaylist;
      setActiveSong();
      return { ...state };
    case "SET_CURR_INDEX":
      const { index } = action as SetCurrIndex;
      state.currIndex = index;
      setActiveSong();
      return { ...state };
    case "SET_CURR_INDEX_AND_PLAYLIST":
      const {
        playlistID: playlistIDSetCurrPlaylistAndIndex,
        index: indexSetCurrPlaylistAndIndex,
      } = action as SetCurrIndexAndPlaylistAction;
      state.selectedSong = { ...state.selectedSong };
      if (playlistIDSetCurrPlaylistAndIndex !== undefined) {
        state.currPlaylist = playlistIDSetCurrPlaylistAndIndex;
        state.selectedSong.playlistID = playlistIDSetCurrPlaylistAndIndex;
      }
      if (indexSetCurrPlaylistAndIndex !== undefined) {
        state.currIndex = indexSetCurrPlaylistAndIndex;
        state.selectedSong.index = indexSetCurrPlaylistAndIndex;
      }
      setActiveSong();
      return { ...state };
    case "SET_BURST_MODE":
      const { isBurstMode } = action as SetIsBurstAction;
      state.isBurstMode = isBurstMode;
      return { ...state };
    case "SET_FRIEND_ID_LIST":
      const { friendIDList } = action as SetFriendIDListAction;
      state.friendIDList = friendIDList;
      return { ...state };
    default:
      return { ...state };
  }
}
