import gradientPng from "../../../assets/pngs/Black8020gradient.png";
import { black } from "../../../utilities/colors";

import Palette from "react-palette";
type Props = { image?: string; backgroundColor?: string };

export default function SongPageBackground({ image, backgroundColor }: Props) {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: black,
        zIndex: -1,
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor,
          opacity: 0.5,
        }}
      />
    </div>
  );
}
