import statsig from "statsig-js";
import {
  isAndroid,
  STATSIG_CLIENT_KEY,
  MIXPANEL_TOKEN,
  MIXPANEL_DEBUG,
  version,
} from "../constants";
import { getAdFrequency } from "./ads";
import mixpanel from "mixpanel-browser";
import {
  getCurrentSongID,
  getCurrIndex,
  getCurrPlaylistID,
} from "../controlModule/trackController";
import { getIsBurstMode } from "../redux/interfaces/contentInterface";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import {
  DATADOG_LOG_TOKEN,
  DATADOG_RUM_TOKEN,
  DATADOG_ENVIRONMENT,
  ENDPOINT,
} from "../constants";
import { sBrowser } from "./browser";
import { getIdToken } from "../users/auth";

var defaultData: any;
var locationAndIP: any;
var userId: any;
export async function initLogging(
  userID: string,
  env: "production" | "staging" | "development" | undefined,
) {
  /*datadogRum.init({
    applicationId: '0866086c-c6c1-4f22-8e3e-53b8ebc79025',
    clientToken: DATADOG_RUM_TOKEN,
    site: 'datadoghq.com',
    service:'fwaygo-mini',
    env: DATADOG_ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel:'mask-user-input',
  });
  datadogLogs.init({
    clientToken: DATADOG_LOG_TOKEN,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    env: DATADOG_ENVIRONMENT,
    
  });*/
  locationAndIP = await getLocationAndIP();
  userId = userID;
  mixpanel.init(MIXPANEL_TOKEN, { debug: MIXPANEL_DEBUG });
  defaultData = {
    userID: userID,
    custom: { adFrequency: getAdFrequency().toString() },
  };
  await statsig.initialize(STATSIG_CLIENT_KEY, defaultData, {
    environment: { tier: env },
  });
}

export function logEvent(event: string, value: string, metadata: any) {
  const dataPayload = {
    value,
    ...metadata,
    songId: getCurrentSongID(),
    currPlaylist: getCurrPlaylistID(),
    currIndex: getCurrIndex(),
    songMode: getIsBurstMode() ? "BURST" : "SONG",
    platform: isAndroid ? "Android" : "iOS",
    defaultData,
    version,
  };

  console.log("logEvent called");
  try {
    mixpanel.track(event, dataPayload);
  } catch (e) {
    console.log("logEvent MIXPANEL ERROR", e);
  }
  try {
    statsig.logEvent(event, value, dataPayload);
  } catch (e) {
    console.log("logEvent statsig ERROR", e);
  }
  /*try {
    datadogRum.addAction(event, dataPayload);
  } catch (e) {
    console.log("datadogRum ERROR", e);
  }
  try {
    datadogLogs.logger.info(event, dataPayload);
  } catch (e) {
    console.log("datadogLogs ERROR", e);
  }*/
  try {
    logSQL(event, value, metadata);
  } catch (e) {
    console.log("logEvent SQL ERROR", e);
  }
}

async function getLocationAndIP() {
  let res = await fetch("https://geolocation-db.com/json/");
  let data = await res.json();
  const ip = data.IPv4;
  res = await fetch(`https://ipapi.co/${ip}/json/`);
  data = await res.json();
  return [data.city, data.country_name, data.region, ip];
}

function logSQL(event: string, value: string, metadata: any) {
  const [metadata1, metadata2, metadata3, metadata4, metadata5] =
    dissectMetadata(metadata);
  const [
    song_id,
    curr_playlist,
    curr_index,
    song_mode,
    platform,
    user_id,
    ad_frequency,
  ] = getDefaultData();
  const [city, country, state, ip] = locationAndIP;
  const user_agent = window.navigator.userAgent;
  const screen_height = window.screen.height.toString();
  const screen_width = window.screen.width.toString();
  const timestamp = new Date().toISOString();
  const os = window.navigator.platform;
  const browser = sBrowser;
  const body = {
    event,
    value,
    song_id,
    curr_playlist,
    curr_index,
    song_mode,
    platform,
    user_id,
    ad_frequency,
    metadata1,
    metadata2,
    metadata3,
    metadata4,
    metadata5,
    user_agent,
    screen_height,
    screen_width,
    timestamp,
    ip,
    city,
    state,
    country,
    os,
    browser,
  };
  console.log("logEvent sql body", body);

  getIdToken().then((id_token) => {
    fetch(ENDPOINT + "/metrics/mini-event", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: id_token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.log("logEvent sql response", response);
        if (!response.ok) {
          console.log(
            "logEvent sql error 1",
            response.ok,
            response.status,
            response.text,
          );
          return null;
        }
        return response.json().catch();
      })
      .then((json) => {
        console.log("logEvent sql success", json);
      })
      .catch((error) => {
        console.log("logEvent sql error", error);
      });
  });
}

function dissectMetadata(metadata: any) {
  const metadataKeys = Object.keys(metadata ?? {});
  let metadata1 = "";
  let metadata2 = "";
  let metadata3 = "";
  let metadata4 = "";
  let metadata5 = "";
  if (metadataKeys.length > 0) {
    metadata1 = metadata[metadataKeys[0]].toString();
    if (metadataKeys.length > 1) {
      metadata2 = metadata[metadataKeys[1]].toString();
      if (metadataKeys.length > 2) {
        metadata3 = metadata[metadataKeys[2]].toString();
        if (metadataKeys.length > 3) {
          metadata4 = metadata[metadataKeys[3]].toString();
          if (metadataKeys.length > 4) {
            metadata5 = metadata[metadataKeys[4]].toString();
          }
        }
      }
    }
  }
  return [metadata1, metadata2, metadata3, metadata4, metadata5];
}

function getDefaultData() {
  return [
    getCurrentSongID(),
    getCurrPlaylistID(),
    getCurrIndex().toString(),
    getIsBurstMode() ? "BURST" : "SONG",
    isAndroid ? "Android" : "iOS",
    userId,
    getAdFrequency().toString(),
  ];
}
