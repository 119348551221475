import { capitalizeString } from "./utilities";

type GenrePlaylistDataType = {
  banner: playlistImageDataType;
  square?: playlistImageDataType;
  titleName?: string;
};

type playlistImageDataType = {
  url?: string;
  asset?: any;
};

const genreData: { [genre: string]: GenrePlaylistDataType } = {
  fwaygoCommunity: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/fwaygoCommunity.png"),
      // url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/fwaygoCommunity.png",
    },
  },
  top40: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/fwaygotop40.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/fwaygotop40.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/fwaygotop40.png",
    },
  },
  artiststowatch: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/artiststowatch.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/artiststowatch.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/artiststowatch.png",
    },
  },
  hiphop: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/hiphop.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/hiphop.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/hiphop.png",
    },
    titleName: "Hip-Hop",
  },
  pop: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/pop.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/pop.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/pop.png",
    },
  },
  "r&b": {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/r&b.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/r&b.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/r&b.png",
    },
    titleName: "R&B",
  },
  rock: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/rock.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/rock.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/rock.png",
    },
  },
  rap: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/rap.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/rap.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/rap.png",
    },
  },
  trap: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/trap.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/trap.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/trap.png",
    },
  },
  country: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/country.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/country.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/country.png",
    },
  },
  instrumental: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/instrumental.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/instrumental.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/instrumental.png",
    },
  },
  indie: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/indie.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/indie.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/indie.png",
    },
  },
  alt: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/alt.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/alt.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/alt.png",
    },
    titleName: "Alternative",
  },
  edm: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/edm.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/edm.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/edm.png",
    },
    titleName: "EDM",
  },
  dance: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/dance.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/dance.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/dance.png",
    },
  },
  drill: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/drill.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/drill.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/drill.png",
    },
  },
  melodic: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/melodic.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/melodic.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/melodic.png",
    },
  },
  hyperpop: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/hyperpop.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/hyperpop.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/hyperpop.png",
    },
  },
  punk: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/punk.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/punk.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/punk.png",
    },
  },
  chill: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/chill.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/chill.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/chill.png",
    },
  },
  lit: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/lit.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/lit.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/lit.png",
    },
  },
  global: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/global.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/global.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/global.png",
    },
  },
  podcast: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/podcast.png"),
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/podcast.png",
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/podcast.png",
    },
  },
  top8: {
    banner: {
      // asset: require("../assets/pngs/playlistGenres/top8.png"),
    },
    square: {
      url: "https://dp6knr9126b6l.cloudfront.net/assets/playlistGenres/squareBanners/top8.png",
    },
  },
};

const featuredPlaylistsMetadata: {
  [playlist: string]: PlaylistMetadata | undefined;
} = {
  upNext: {
    title: "Up Next",
    type: "Curated",
    description: "Featuring the future of music.",
    url: "https://dp6knr9126b6l.cloudfront.net/mini/featuredPlaylists/upNext.png",
  },
  noSkips: {
    title: "No Skips",
    type: "Curated",
    description: "The best hip hop tracks. Press play & let it run.",
    url: "https://dp6knr9126b6l.cloudfront.net/mini/featuredPlaylists/noSkips.png",
  },
  popArt: {
    title: "No Skips",
    type: "Curated",
    description: "The best hip hop tracks. Press play & let it run.",
    url: "https://dp6knr9126b6l.cloudfront.net/mini/featuredPlaylists/popArt.png",
  },
  inMyFeels: {
    title: "In My Feels",
    type: "Curated",
    description: "For all the certified lover boys & girls.",
    url: "https://dp6knr9126b6l.cloudfront.net/mini/featuredPlaylists/inMyFeels.png",
  },
  defy: {
    title: "DEFY",
    type: "Curated",
    description: "Keeping rock alive.",
    url: "https://dp6knr9126b6l.cloudfront.net/mini/featuredPlaylists/defy.png",
  },
  cooknUpCountry: {
    title: "Cook’n Up Country",
    type: "Curated",
    description: "Tailgates, campfires, cookouts, everything country.",
    url: "https://dp6knr9126b6l.cloudfront.net/mini/featuredPlaylists/cooknUpCountry.png",
  },
  bottleService: {
    title: "Bottle Service",
    type: "Curated",
    description: "Built to make you dance, vibe & rave.",
    url: "https://dp6knr9126b6l.cloudfront.net/mini/featuredPlaylists/bottleService.png",
  },
  sessions: {
    title: "Sessions",
    type: "Curated",
    description:
      "Singer, songwriters, who cook up all the vibes from their bedroom.",
    url: "https://dp6knr9126b6l.cloudfront.net/mini/featuredPlaylists/sessions.png",
  },
  meltingPot: {
    title: "Melting Pot",
    type: "Curated",
    description: "No borders. Where all styles come together.",
    url: "https://dp6knr9126b6l.cloudfront.net/mini/featuredPlaylists/meltingPot.png",
  },
  drillDaily: {
    title: "Drill Daily",
    type: "Curated",
    description: "All the sounds to get sturdy to.",
    url: "https://dp6knr9126b6l.cloudfront.net/mini/featuredPlaylists/drillDaily.png",
  },
  hypeR: {
    title: "HYPE-R",
    type: "Curated",
    description: "Definition of avant garde.",
    url: "https://dp6knr9126b6l.cloudfront.net/mini/featuredPlaylists/hypeR.png",
  },
  bookAFlight: {
    title: "Book A Flight",
    type: "Curated",
    description: "Music is everywhere. Top sounds all over the world.",
    url: "https://dp6knr9126b6l.cloudfront.net/mini/featuredPlaylists/bookAFlight.png",
  },
  "300": {
    title: "300",
    type: "Curated",
    description: "",
    url: "https://dp6knr9126b6l.cloudfront.net/mini/featuredPlaylists/300-v2.png",
  },
};

const genres: Genre[] = [
  "hiphop",
  "pop",
  "r&b",
  "rock",
  "rap",
  "trap",
  "country",
  "instrumental",
  "indie",
  "alt",
  "edm",
  "dance",
  "drill",
  "melodic",
  "hyperpop",
  "punk",
  "chill",
  "lit",
  "global",
  "podcast",
];

export const genresLength = genres.length;

const featuredPlaylists: FeaturedPlaylist[] = [
  "upNext",
  "300",
  "noSkips",
  "popArt",
  "inMyFeels",
  "defy",
  "cooknUpCountry",
  "bottleService",
  "sessions",
  "meltingPot",
  "drillDaily",
  "hypeR",
  "bookAFlight",
];

export function getPlaylistData(playistID: string) {
  let title = `#${playistID}`;
  let type: PlaylistType = "Tag";
  let description = "";
  if (playistID === "topSongs") {
    type = "TopChart";
    title = "Top Entries";
    description = "Our greatest hits and latest winners";
  } else if (genres.includes(playistID as any)) {
    title = genreData[playistID]?.titleName ?? capitalizeString(playistID);
    type = "Genre";
  }
  return { title, type, description };
}

const genders = ["Male", "Female", "Nonbinary", "I prefer not to say"];

const reactionEmojis = ["🔥", "❤", "👍", "😂", "😢", "😡"];

const artistData = [
  {
    genre: "Hip-Hop",
    artists: [
      "Drake",
      "Kanye West",
      "J. Cole",
      "Travis Scott",
      "Young Thug",
      "Future",
      "Lil Baby",
      "Migos",
      "Lil Uzi",
      "Playboi Carti",
      "Cardi B",
      "Tyler The Creator",
      "NBA Youngboy",
      "Jack Harlow",
      "Moneybagg Yo",
      "Lil Durk",
      "DaBaby",
      "Megan Thee Stallion",
      "Gunna",
      "A$AP Rocky",
    ],
  },
  {
    genre: "R&B",
    artists: [
      "Chris Brown",
      "Tory Lanez",
      "Miguel",
      "H.E.R",
      "SZA",
      "Giveon",
      "Jhene Aiko",
      "Jacquees",
      "Bryson Tiller",
      "6lack",
      "Usher",
      "Alicia Keys",
      "Ty Dolla Sign",
      "Morray",
      "Summer Walker",
      "Kehlani",
      "Teyana Taylor",
      "Partynextdoor",
      "Brent Faiyaz",
      "Tpain",
    ],
  },
  {
    genre: "Pop",
    artists: [
      "The Weeknd",
      "Lil Nas X",
      "Billie Eilish",
      "Post Malone",
      "BTS",
      "Justin Bieber",
      "Ariana Grande",
      "Taylor Swift",
      "Ed Sheeran",
      "Dua Lipa",
      "Olivia Rodrigo",
      "Doja Cat",
      "Kid Laroi",
      "Halsey",
      "Shawn Mendes",
      "Harry Styles",
      "Lizzo",
      "Maroon 5",
      "One Republic",
      "Bruno Mars",
    ],
  },
  {
    genre: "Country",
    artists: [
      "Morgan Wallen",
      "Chris Stapleton",
      "Dan+Shay",
      "Luke Combs",
      "Florida Georgia Line",
      "Kane Brown",
      "Mitchell Tenpenny",
      "Luke Bryan",
      "Parker McCollum",
      "Sam Hunt",
      "Breland",
      "Jimmie Allen",
      "Carrie Underwood",
      "Thomas Rhett",
      "Dierks Bentley",
      "Lee Brice",
      "Jason Aldean",
      "Kacey Musgraves",
      "Kelsea Ballerini",
      "Rascal Flatts",
    ],
  },
  {
    genre: "Rock",
    artists: [
      "Machine Gun Kelly",
      "Iann Dior",
      "Yungblud",
      "JXDN",
      "Modsun",
      "Greta Van Fleet",
      "Lil Huddy",
      "Blink 182",
      "Green Day",
      "Nirvana",
      "Blackbear",
      "Motley Crue",
      "The Killers",
      "Red Hot Chili Peppers",
      "Avril Lavigne",
      "Girlfriends",
      "My Chemical Romance",
      "Nine Inch Nails",
      "Fall Out Boy",
      "Linkin Park",
    ],
  },
  {
    genre: "Alternative",
    artists: [
      "Tame Impala",
      "Coldplay",
      "Foo Fighters",
      "Weezer",
      "The Lumineers",
      "John Mayer",
      "Dave Matthews Band",
      "Mumford & Sons",
      "Imagine Dragons",
      "Finneas",
      "Lana Del Ray",
      "Lorde",
      "Foster The People",
      "The 1975",
      "Panic! At The Disco",
      "Twenty One Pilots",
      "Charlie XCX",
      "Tove Lo",
      "Glass Animals",
      "Tai Verdes",
    ],
  },
  {
    genre: "Latin",
    artists: [
      "Bad Bunny",
      "Jbalvin",
      "Maluma",
      "Anuel AA",
      "Karol G",
      "Jhay Cortez",
      "Ozuna",
      "Lunay",
      "Rauw Alejandro",
      "Nicky Jam",
      "Myke Towers",
      "Becky G",
      "Tini",
      "Farruko",
      "Manuel Turizo",
      "Daddy Yankee",
      "Bryant Myers",
      "Shakira",
      "Rosalia",
      "Nio Garcia",
    ],
  },
  {
    genre: "Electronic",
    artists: [
      "Calvin Harris",
      "Marshmello",
      "Diplo",
      "Tiesto",
      "Martin Garrix",
      "Rufus Du Sol",
      "Fisher",
      "Steve Aoki",
      "DJ Snake",
      "Zedd",
      "The Chainsmokers",
      "Carnage",
      "Disclosure",
      "Skrillex",
      "Avicii",
      "David Guetta",
      "Alesso",
      "Camelphat",
      "Guy Gerber",
      "Swedish House Mafia",
    ],
  },
];

const topEntriesID = "topSongs";

const tagPlaylists = genres;

const chartsPlaylists = [topEntriesID];

const socialPlaylists = ["mutualDiscoveries", "communityFavorites"];

export {
  genres,
  genders,
  genreData,
  featuredPlaylists,
  featuredPlaylistsMetadata,
  reactionEmojis,
  artistData,
  topEntriesID,
  tagPlaylists,
  chartsPlaylists,
  socialPlaylists,
};
