import { useTestUserAsFriend } from "../../constants";
import {
  pushSongAction,
  setSongsAction,
  setUsersAction,
  setPlaylistAction,
  setCurrPlaylistAction,
  setCurrIndexAction,
  setIsBurstModeAction,
  setFriendIDListAction,
  setPlaylistsMetadataAction,
  setCurrIndexAndPlaylistAction,
} from "../actions/content.actions";
import { store } from "../useRedux";

export function pushSongs(
  songList: Song[],
  userList: User[],
  playlistID: string,
  options?: PushSongActionOptions,
) {
  store.dispatch(pushSongAction(songList, userList, playlistID, options));
}

export function setSongs(songList: Song[], options?: any) {
  store.dispatch(setSongsAction(songList, options));
}

export function setUsers(userList: User[], options?: any) {
  store.dispatch(setUsersAction(userList, options));
}

export function setPlayist(
  playistID: string,
  songIDList: string,
  options?: any,
) {
  store.dispatch(setPlaylistAction(playistID, songIDList, options));
}

export function setPlaylistsMetadata(
  playlistsMetadata: PlaylistsMetaDataMap,
  setID?: string,
  playlistIDs?: string[],
) {
  store.dispatch(
    setPlaylistsMetadataAction(playlistsMetadata, setID, playlistIDs),
  );
}

export function setCurrPlaylist(playistID: string) {
  store.dispatch(setCurrPlaylistAction(playistID));
}

export function setCurrIndex(index: number) {
  store.dispatch(setCurrIndexAction(index));
}

export function setCurrIndexAndPlaylist(update: {
  playlistID?: string;
  index?: number;
}) {
  store.dispatch(setCurrIndexAndPlaylistAction(update));
}

export function setIsBurstMode(newIsBurstMode: boolean) {
  store.dispatch(setIsBurstModeAction(newIsBurstMode));
}

export function setFriendIDList(friendIDList: string[]) {
  store.dispatch(setFriendIDListAction(friendIDList));
}

export function getSongMap() {
  return store.getState().content.songMap;
}

export function getSong(songID: string) {
  return store.getState().content.songMap?.[songID];
}

export function getUserMap() {
  return store.getState().content.userMap;
}

export function getPlaylists() {
  return store.getState().content.playlists;
}

export function getPlaylistMetadata() {
  return store.getState().content.playlistsMetaData;
}

// export function getCurrPlaylist() {
//   return store.getState().content.currPlaylist;
// }

// export function getCurrIndex() {
//   return store.getState().content.currIndex;
// }

export function getIsBurstMode() {
  return store.getState().content.isBurstMode;
}

export function getPlaylist(playlistID: string) {
  return store.getState().content.playlists?.[playlistID];
}

export function getFriendIDList() {
  const friendIDList = store.getState().content?.friendIDList;
  if (useTestUserAsFriend)
    return [...friendIDList, "iusrD8APbAeUCrT2rK13Pa4g6emjqVC3"];
  return friendIDList;
}

export function getPlaylistSet(setID: string) {
  return store.getState().content.playlistSets?.[setID];
}

export function getContentData() {
  return store.getState().content;
}

//TODO handle burst mode
