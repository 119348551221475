import sc from "@snapchat/snapcanvas-sdk";
import {
  MAX_ADS_PER_MIN,
  isShowingAds,
  REACT_APP_AD_SLOT_ID,
  MIN_TO_AD_START,
  isAndroid,
} from "../constants";
import { getIsFocused } from "./scState";
import { logEvent } from "./statsig";

let thisAd: SnapchatAd;

export default class SnapchatAd {
  ads_initialized: boolean;
  slotId: string;
  ad_start: number;

  constructor(slotId: string) {
    this.slotId = slotId;
    this.ads_initialized = false;
    this.ad_start = 0;
    this.onStartup();
  }

  onStartup = async () => {
    // Ensure that sc.initialize() is only called ONCE
    console.log("onStartup");

    // Ensure that sc.initializeAds() is only called ONCE
    // if (!this.ads_initialized) {
    //   await sc.initializeAds([this.slotId]);
    //   this.ads_initialized = true;
    // }

    // // Checks for adReady event
    // if (sc.app) sc.app.on("adReady", this.onAdReady, this);

    // // Checks for adComplete event
    // if (sc.app) sc.app.on("adComplete", this.onAdComplete, this);

    // // Checks for unconsumed ads from previous sessions
    // await this.giveUnconsumedRewards();
    // console.log("startup finished");
  };

  onAdComplete(response: any) {
    if (response.slotId === this.slotId) {
      // Deliver reward to the user
      sc.consumeAd(response.requestId); // Consume Ad
    }
    const ad_end = new Date().getTime();
    const ad_time = (ad_end - this.ad_start) / 1000;
    logEvent("AD TIME", "", { time: ad_time });
  }

  onAdReady(response: any) {
    if (response.slotId === this.slotId) {
      // Ad is ready
      //Utils.enableButton('watchAdButton', true);
    }
  }

  showAd = async (callback: any) => {
    // Check if Ad is ready right before showing the ad
    const isAdReady = await sc.isAdReady(this.slotId);
    this.ad_start = new Date().getTime();
    //console.re.log(isAdReady, "isAdReady");
    if (isAdReady.isReady) {
      // Ad is ready, let's show the ad
      const payload = "";
      sc.watchAd(this.slotId, payload);
      if (sc.app) {
        sc.app.unbind("adComplete");
        sc.app.on("adComplete", callback, this);
        sc.app.on("adComplete", this.onAdComplete, this);
      }
    } else {
      callback();
      // Ad is not ready, try again later
    }
  };

  giveUnconsumedRewards = async () => {
    // Check for Unconsumed ads
    const response = await sc.getUnconsumedAds();
    if (response?.unconsumedAds && response?.unconsumedAds?.length > 0) {
      // For each watched ad, that wasn't rewarded:
      response?.unconsumedAds?.forEach((rv) => {
        // Deliver reward to the user
        sc.consumeAd(rv.requestId); // Consume the ad
      });
    }
  };
}

export function initializeAds() {
  //console.re.log("initializeAds", REACT_APP_AD_SLOT_ID);
  if (REACT_APP_AD_SLOT_ID) thisAd = new SnapchatAd(REACT_APP_AD_SLOT_ID);
}

const minuteMS = 60 * 1000;
const adIntervalMs = (1 / MAX_ADS_PER_MIN) * minuteMS;
let previousAdTime = Date.now() + MIN_TO_AD_START * minuteMS;
let adFrequency = 0;

export function showAd(callback: any) {
  previousAdTime = Date.now();
  thisAd?.showAd(callback);
  logEvent("SHOW AD", "", "");
}

export function shouldShowAd() {
  const shouldShow =
    isShowingAds &&
    !isAndroid &&
    adFrequency &&
    !isPageHidden() &&
    getIsFocused() &&
    window.location.href.includes("/songPlayer") &&
    Date.now() - previousAdTime > adIntervalMs / (adFrequency * 0.1);
  return shouldShow;
}

export function isPageHidden() {
  return document.hidden;
}

export function getAdFrequency() {
  return adFrequency;
}

export function setAdFrequency(frequency: number) {
  //Currently either 0 or 10
  adFrequency = frequency;
}
