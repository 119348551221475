import {
  getNextSongURL,
  pause,
  play,
  selectNextCurrentPlaylist,
} from "../trackController";
import { emitEvent } from "../../utilities/events";
import Hls from "hls.js";
import { isAutoplayDisabled } from "../../constants";
import { isPageHidden, shouldShowAd, showAd } from "../../utilities/ads";
import { isShareInProgress } from "../../utilities/scState";

export enum PlaybackAudioStates {
  None = "NONE",
  // Ready = "READY",
  Playing = "PLAYING",
  Paused = "PAUSED",
  // Stopped = "STOPPED",
  // Seeking = "SEEKING",
  // Buffering = "BUFFERING",
  // Connecting = "CONNECTING",
  // ERROR = "ERROR",
}

const hlsConfig = {
  debug: true,
};

let thisAudioUrl = "";
let nextAudioURL = "";
let playBackState: PlaybackAudioStates = PlaybackAudioStates.None;

export let audio: HTMLAudioElement | null;
function setAudio(newAudio: HTMLAudioElement | null, newThisAudioURL: string) {
  audio?.remove?.();
  audio = newAudio;
  thisAudioUrl = newThisAudioURL;
}

let nextAudio: HTMLAudioElement | null = null;
function setNextAudio(
  newNextAudio: HTMLAudioElement | null,
  newNextAudioURL: string,
) {
  nextAudio?.remove?.();
  nextAudio = newNextAudio;
  nextAudioURL = newNextAudioURL;
}

let nextHls: Hls | null = null;
function setNextHLS(newNextHLS: Hls | null, newNextAudioURL: string) {
  // try {
  // nextHls?.destroy();
  // } catch {
  //   console.re.log("falied to destroy");
  // }
  nextHls = newNextHLS;
  nextAudioURL = newNextAudioURL;
}
// eslint-disable-next-line
let isSwitching: boolean = false;
let hasPlayed = false;

export function getHasPlayed() {
  return hasPlayed;
}

export const setIsSwitching = (value: boolean) => (isSwitching = value);

function animateAudioEvents() {
  handleProgress(audio?.currentTime, audio?.duration);
  requestAnimationFrame(animateAudioEvents);
}
requestAnimationFrame(animateAudioEvents);

export type AudioPositionDataType = {
  currentTime: number;
  duration: number;
};

function handleProgress(
  currentTime: number | undefined,
  duration: number | undefined,
) {
  // //console.re.log("handleProgress", currentTime, duration);
  if (!currentTime || !duration) return;
  const audioPositionData: AudioPositionDataType = {
    currentTime,
    duration,
  };
  emitEvent("audio-position", audioPositionData);
}

function attachEventListenersToThisAudio() {
  //EVENT LISTENERS: thisAudio
  //each time thisAudio is updated (loadThisAudio or skipToNextAudio) the following listeners are attached to thisAudio
  //PLAYBACK ENDED: when playback ends call activateNextAudio
  //LOADING or PLAYBACK_PROGRESS: when loading reaches LOADING_THRESHOLD call loadNextAudio
  //INTERNAL
  // audio?.addEventListener("canplay", (e: Event) => {
  //   playThisAudio();
  // });

  audio?.addEventListener("canplaythrough", (e: Event) => {
    loadNextAudio(getNextSongURL());
  });

  //EXTERNAL
  audio?.addEventListener("playing", (e: any) => {
    if (!isAutoplayDisabled && e.target.src !== thisAudioUrl) {
      //just in case
      e.target?.pause();
      return;
    }
    setPlaybackAudioState(PlaybackAudioStates.Playing);
    emitEvent("playback-state", e);
  });

  audio?.addEventListener("pause", (e: any) => {
    if (!isAutoplayDisabled && e.target.src !== thisAudioUrl) {
      return;
    }
    setPlaybackAudioState(PlaybackAudioStates.Paused);
    emitEvent("playback-state", e);
  });

  audio?.addEventListener("ended", (e: Event) => {
    if (isShareInProgress()) {
      seekAudio(0);
      return;
    }
    toNextTrack();
  });

  // audio?.addEventListener("seeking", (e: Event) => {
  // });

  // audio?.addEventListener("seeked", (e: Event) => {
  // });
  // audio?.addEventListener("loadStart", (e: Event) => {
  // });
  // audio?.addEventListener("error", (e: ErrorEvent) => {
  // });
}

//cleanup
function cleanupThisAudioEventListeners() {
  audio?.removeEventListener("canplaythrough", (e: Event) => {});
  audio?.removeEventListener("playing", (e: Event) => {});
  audio?.removeEventListener("pause", (e: Event) => {});
  audio?.removeEventListener("ended", (e: Event) => {});

  // audio?.removeEventListener("seeking", (e: Event) => {});
  // audio?.removeEventListener("seeked", (e: Event) => {});

  // audio?.removeEventListener("loadStart", (e: Event) => {});
  // audio?.removeEventListener("error", (e: ErrorEvent) => {});

  // audio?.removeEventListener("timeupdate", (e: Event) => {});
}

//LOADING CONTROL
function loadThisAudio(url: string) {
  //Create new Audio and attach event liseners
  if (isAutoplayDisabled) return loadThisAudioHLS(url);
  if (!url) {
    return;
  }
  cleanupThisAudioEventListeners();
  audio?.pause();
  if (!audio) setAudio(new Audio(url), url);
  else {
    audio.src = url;
    thisAudioUrl = url;
  }
  // audio.autoplay = true;

  attachEventListenersToThisAudio();
}

function loadThisAudioHLS(url: string) {
  // console.re.warn("loadThisAudioHLS called", url);
  if (!url) {
    return;
  }
  if (Hls.isSupported()) {
    // pauseThisAudio();
    thisAudioUrl = url;
    if (!audio) {
      setAudio(new Audio(), url);
      attachEventListenersToThisAudio();
    } else seekAudio(0);
    try {
      if (audio) {
        const hls = new Hls(hlsConfig);
        hls.loadSource(url);
        hls.attachMedia(audio);
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {});
      }
    } catch (e) {
      // console.re.warn("loadThisAudioHLS caught HLS Error", url, audio, e);
    }
  }
}

export function loadNextAudio(url: string | undefined) {
  if (isAutoplayDisabled) return loadNextAudioHLS(url);
  // setPlaybackAudioState(PlaybackAudioStates.Buffering);
  if (url && url !== thisAudioUrl && url !== nextAudioURL) {
    setNextAudio(new Audio(url), url);
  }
}

let isAdPlaying = false;
export function interstitchAd() {
  const success = pauseThisAudio();
  if (!success) isAdPlaying = false;
  isAdPlaying = true;
  showAd(() => {
    //console.re.log("interstitchAd over");
    isAdPlaying = false;
    playThisAudio();
  });
}

function loadNextAudioHLS(url: string | undefined) {
  if (url !== thisAudioUrl) {
    if (Hls.isSupported()) {
      try {
        // nextAudioURL = url as string;
        if (!url) return;
        const newNextHls = new Hls(hlsConfig);
        newNextHls.loadSource(url);
        setNextHLS(newNextHls, url);
      } catch (e) {
        // console.re.warn("loadNextAudioHLS hls error", url, nextHls, e);
      }
    }
  }
}

export function activateNextAudio() {
  if (isAutoplayDisabled) {
    // console.re.log("activateNextAudio calling activateNextAudioHLS");
    try {
      return activateNextAudioHLS();
    } catch (e: any) {
      // console.re.log(
      //   "activateNextAudio activateNextAudioHLS error",
      //   e,
      //   e?.message,
      // );
    }
  }
  if (!nextAudio || !nextAudioURL) {
    return false;
  }

  cleanupThisAudioEventListeners();
  //Clean up old thisAudio
  //update thisAudio and attach event listeners
  audio?.pause();
  setAudio(nextAudio, nextAudioURL);
  setNextAudio(null, "");

  attachEventListenersToThisAudio();
  //Clean up old nextAudio
  return true;
}

export function activateNextAudioHLS() {
  // console.re.log("activateNextAudioHLS called", nextHls, nextAudioURL, audio);

  if (!nextHls || !nextAudioURL || !audio) {
    // console.re.log("activateNextAudioHLS", nextHls, nextAudioURL, audio);
    return false;
  }

  if (Hls.isSupported()) {
    pauseThisAudio();
    seekAudio(0);
    try {
      nextHls.attachMedia(audio);
      // console.re.log("activateNextAudioHLS attachMedia");

      nextHls.on(Hls.Events.MEDIA_ATTACHED, function () {
        // console.re.log("activateNextAudioHLS attachMedia success");
      });
      thisAudioUrl = nextAudioURL;
      setNextHLS(null, "");
    } catch (e) {
      // console.re.warn("activateNextAudioHLS HLS error", audio, e);
    }
  }
  //Clean up old nextAudio
  return true;
}

export function loadAudio(url: string) {
  if (url === nextAudioURL && activateNextAudio()) {
    if (shouldShowAd()) {
      interstitchAd();
      return "SC_AD";
    } else return url;
  }
  loadThisAudio(url);
  return url;
}

//PLAYING CONTROL
function setPlaybackAudioState(state: PlaybackAudioStates) {
  playBackState = state;
}

export function getPlaybackAudioState() {
  return playBackState;
}

export function playAudioUserAction() {
  isPauseRequested = false;
  if (isAdPlaying) isAdPlaying = false;
  return playThisAudio();
}

export function pauseAudioUserAction() {
  if (isPlayEventInProgress) {
    isPauseRequested = true;
    return;
  }
  if (isAdPlaying) isAdPlaying = false;
  return pauseThisAudio();
}

var isPlayEventInProgress = false;
export function playThisAudio() {
  console.log("playThisAudio", audio);
  if (isAdPlaying) return false;
  if (!audio) return false;
  isPlayEventInProgress = true;
  const success = audio
    ?.play()
    .then(() => {
      // showToast("Played", "");
      // console.re.log("Play");
      hasPlayed = false;
      finishPlayEvent();
      return true;
    })
    .catch((e: Error) => {
      // showToast("Play error", "");
      // console.re.log(
      //   "Play Error",
      //   e.message,
      //   e.cause,
      //   e.name,
      //   e.stack,
      //   audio,
      //   thisAudioUrl,
      //   nextAudioURL,
      // );
      finishPlayEvent();
      return false;
    });
  audio.volume = 1;
  return success;
}

function finishPlayEvent() {
  isPlayEventInProgress = false;
  if (isPauseRequested) pauseThisAudio();
}

var isPauseRequested = false;
export function pauseThisAudio() {
  // setPlaybackAudioState(PlaybackAudioStates.Paused);
  // console.re.log("Pause", audio);
  if (!audio) return false;
  audio?.pause();
  isPauseRequested = false;
  return true;
}

export function togglePlayState() {
  if (playBackState === PlaybackAudioStates.Playing) {
    pause();
    return "pause";
  } else {
    play();
    return "playing";
  }
}

export async function resetAudio() {
  // cleanupThisAudioEventListeners();
  pauseThisAudio();
  setAudio(null, "");
  setNextAudio(null, "");
  setNextHLS(null, "");
  setPlaybackAudioState(PlaybackAudioStates.None);
}

export async function seekAudio(rangeValue: number) {
  if (!audio) {
    return;
  }
  const target = rangeValue * audio.duration;
  if (audio && audio.duration && !isNaN(target)) {
    audio.currentTime = target;
  }
}

export const toNextTrack = () => {
  selectNextCurrentPlaylist();
};

export function seekPercent(progressPercent: number) {}

export function seekMS(progressMS: number) {}
