import { ENDPOINT } from "../../constants";
import { getIdToken } from "../../users/auth";

export async function executeQuery(query: string): Promise<any | null> {
  // console.log("[executeQuery] request", endpoint ?? ENDPOINT, query);
  const id_token = await getIdToken();
  console.log("id_token", id_token);
  return fetch(`${ENDPOINT}/graphql`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: id_token,
    },
    body: JSON.stringify({ query }),
  })
    .then(async (res) => {
      console.log("[executeQuery] result", res.ok, res.body, res.status, query);
      if (!res.ok) return null;
      return await res.json().catch();
    })
    .catch((err) => {
      console.log("[executeQuery] error", err);
      throw err;
    });
}

export const input = (limit: number, cursor?: string) => {
  return `{
        limit: ${limit},
        ${cursor?.length ? `cursor: ${cursor},` : ""}
      }`;
};

export const createArrayString = (strArr: string[]) => {
  let toRet = "[";
  for (const str of strArr) {
    toRet += `\"${str}\", `;
  }
  toRet = toRet.substring(toRet.length - 2) + "]";
  return toRet;
};

export function processCursor(newCursor: string | undefined) {
  return (
    (newCursor && JSON.stringify(newCursor)) ||
    (newCursor === "" ? "" : undefined)
  );
}

export function extractUsersFromSongs(songsRaw?: Song[]) {
  if (!songsRaw) return {};
  const users: User[] = [];
  const songs: Song[] = [];
  songsRaw.forEach((song) => {
    if (song.exists) songs.push(song);
    if (!song.artist) return;
    users.push(song.artist);
  });
  return { songs, users };
}
