import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { rootReducer } from "./reducers";
import loggerMiddleware from "./middleware/logger";
import monitorReducersEnhancer from "./enhancers/monitorReducer";

function configureStore(preloadedState?: any) {
  const middlewares = [loggerMiddleware, thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
  const composedEnhancers: any = compose(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}

export const store = configureStore();
