import sc from "@snapchat/snapcanvas-sdk";

const focusMap: { [eventKey: string]: boolean } = {};

let isFocused = true;

function handleEvent(resp: { event: string }, newIsFocused: boolean) {
  const { event } = resp;
  isFocused = newIsFocused;
  focusMap[event] = newIsFocused;
  // console.re.log("scState handleEvent", event, newIsFocused);
}

export function isShareInProgress() {
  // console.re.log(
  //   "scState isShareInProgress",
  //   focusMap["CAMERA_SCREEN"],
  //   focusMap["SEND_TO_SCREEN"],
  // );
  return (
    focusMap["CAMERA_SCREEN"] === false || focusMap["SEND_TO_SCREEN"] === false
  );
}

export function getIsFocused(event?: string) {
  return (event && focusMap[event]) || isFocused;
}

export function initializeStateListeners() {
  if (!sc.app) return;
  sc.app.on("DID_GAIN_FOCUS", handleEvent);
  sc.app.on("DID_LOSE_FOCUS", handleEvent);
}
