import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  DragEvent,
  MouseEvent,
  SetStateAction,
  TouchEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import BifurcatedListItem from "../../../../../components/listitem/BifurcatedListItem";
import { AudioPositionDataType } from "../../../../../controlModule/audioPlayer/audioPlayer";
import { seek } from "../../../../../controlModule/trackController";
import { opacity, white } from "../../../../../utilities/colors";
import {
  createEventListener,
  removeEventListener,
} from "../../../../../utilities/events";
import { sfProTextSemibold } from "../../../../../utilities/fonts";
import { standardMargin } from "../../../../../utilities/scaling";
import { createTimestampString } from "../../../../../utilities/utilities";
import BurstSwitch from "../../../../../songPlayer/components/songPlayerPage/components/songPlayerPageOverlay/components/songPageOverlayFooter/components/burstSwitch/BurstSwitch";
import "./songPageSlider.scss";

const TRACK_THUMB_HEIGHT = 10;
const TRACK_HEIGHT = 5;
const TRACK_THUMB_SCALE_ANIMATION_DURATION = 200;

const TOP_PADDING = 2;

const ACTIVE_COLOR = white;
const INACTIVE_COLOR = opacity(white, 0.5);

const TITLE_FONT_SIZE = 25;
const USERNAME_FONT_SIZE = 16;

const TIME_STRING_MARGIN = 3;
const TIME_FONT_SIZE = 14;

export type Props = {
  song: Song;
  withBurst?: boolean;
};

export default function SongPageSlider({ song, withBurst }: Props) {
  const [progress, setProgress] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const { id, title, artist } = song ?? {};
  const { username, id: userId } = artist ?? {};

  return (
    <div
      style={{
        position: "relative",
        pointerEvents: "initial",
        touchAction: "none",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        paddingTop: TOP_PADDING,
        paddingLeft: standardMargin,
        paddingRight: standardMargin,
        paddingBottom: standardMargin,
      }}
    >
      <ArtistInfoBurst
        id={id}
        userId={userId ?? ""}
        title={title ?? ""}
        username={username ?? ""}
        withBurst={withBurst}
      />
      <Slider setProgress={setProgress} setDuration={setDuration} />
      <TimeDisplay progress={progress} duration={duration} />
    </div>
  );
}

type SliderProps = {
  setProgress: Dispatch<SetStateAction<number>>;
  setDuration: Dispatch<SetStateAction<number>>;
};

function Slider({ setProgress, setDuration }: SliderProps) {
  const sliderProgressDivRef = useRef<HTMLDivElement | null>(null);
  const sliderThumbRef = useRef<HTMLDivElement | null>(null);
  const isSeekingRef = useRef<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const input = inputRef.current;
    input?.addEventListener("change", handleInputChange);
    input?.addEventListener("input", handleInputDrag);

    return () => {
      input?.removeEventListener("change", handleInputChange);
      input?.addEventListener("input", handleInputDrag);
    };
  }, []);

  // const getDims = () => {
  //   const width = trackRef?.clientWidth ?? 0;
  //   const barWidth = width - standardMargin * 2;
  //   return { width, barWidth };
  // };

  // const [dims, setDims] = useState<{ width: number; barWidth: number }>(
  //   getDims(),
  // );
  // const { width, barWidth } = dims;

  // useEffect(() => {
  //   setDims(getDims());
  //   createEventListener("resize", () => setDims(getDims()));
  //   return () => {
  //     removeEventListener("resize", () => setDims(getDims()));
  //   };
  // }, [trackRef]);

  const handleSetThumbScale = (scale: number) => {
    if (sliderThumbRef.current)
      sliderThumbRef.current.style.transform = `scale(${scale},${scale})`;
  };

  const handleInputDrag = (e: Event) => {
    e.stopPropagation();
    isSeekingRef.current = true;
    const position = Number((e.target as HTMLInputElement).value) / 100;
    handleSetProgress(
      position,
      sliderThumbRef.current,
      sliderProgressDivRef.current,
    );
    // handleSetThumbScale(1.8);
  };

  const handleInputChange = (e: Event) => {
    e.stopPropagation();

    const position = Number((e.target as HTMLInputElement).value) / 100;
    handleSetProgress(
      position,
      sliderThumbRef.current,
      sliderProgressDivRef.current,
    );
    seek(position);
    isSeekingRef.current = false;
    // handleSetThumbScale(1);
  };

  const handleSetProgress = (
    position: number,
    thumbDiv: HTMLDivElement | null,
    progressDiv: HTMLDivElement | null,
  ) => {
    const percentage = position * 100;
    if (thumbDiv) thumbDiv.style.left = `${percentage}%`;
    if (progressDiv) progressDiv.style.width = `${percentage}%`;
  };

  useEffect(() => {
    const handleAudioProgressUpdate = ({
      currentTime,
      duration,
    }: AudioPositionDataType) => {
      if (isSeekingRef.current) return;
      let position: number;
      if (!currentTime || !duration || currentTime >= duration) position = 0;
      else position = Math.min(1, Math.max(0, currentTime / duration));
      if (currentTime) setProgress(position * duration);
      if (duration) setDuration(duration);
      handleSetProgress(
        position,
        sliderThumbRef.current,
        sliderProgressDivRef.current,
      );
    };

    createEventListener("audio-position", handleAudioProgressUpdate);
    return () => {
      removeEventListener("audio-position", handleAudioProgressUpdate);
    };
  }, [sliderThumbRef, sliderProgressDivRef, setProgress, setDuration]);

  return (
    <div className="song-page-slider">
      <div className="input-wrapper">
        <input ref={inputRef} type="range" defaultValue={0} step="any" />
      </div>

      <div className="control-wrapper">
        <div className="rail">
          <div className="inner-rail" ref={sliderProgressDivRef} />
        </div>
        <div ref={sliderThumbRef} className="thumb" />
      </div>
    </div>
    // <div
    //   draggable
    //   // onClick={onClick}
    //   // // onTouchStart={onTouchStart}
    //   // onDragStart={onTouchStart}
    //   // // onTouchMove={onTouchMove}
    //   // onDrag={onTouchMove}
    //   // // onTouchEnd={onTouchEnd}
    //   // onDragEnd={onTouchEnd}
    //   onDragStart={handleDragStart}
    //   // className={"swiper-no-swiping"}
    //   // ref={(ref) => setTrackRef(ref)}
    //   style={{
    //     width: "100%",
    //     position: "relative",
    //     height: TRACK_THUMB_HEIGHT,
    //     display: "flex",
    //     flexDirection: "row",
    //     alignItems: "center",
    //     background: "red",
    //   }}
    // >
    //   {/* <div
    //     style={{
    //       position: "absolute",
    //       height: TRACK_HEIGHT,
    //       width: "100%",
    //       background: INACTIVE_COLOR,
    //       borderRadius: 999,
    //       display: "flex",
    //       flexDirection: "row",
    //       alignItems: "center",
    //       overflow: "hidden",
    //     }}
    //   >
    //     <div
    //       ref={sliderProgressDivRef}
    //       style={{
    //         height: TRACK_HEIGHT,
    //         position: "relative",
    //         background: ACTIVE_COLOR,
    //       }}
    //     />
    //   </div>
    //   <div
    //     ref={sliderThumbRef}
    //     style={{
    //       position: "absolute",
    //       marginLeft: -TRACK_THUMB_HEIGHT / 2,
    //       height: TRACK_THUMB_HEIGHT,
    //       width: TRACK_THUMB_HEIGHT,
    //       borderRadius: "50%",
    //       background: ACTIVE_COLOR,
    //       transition: `transform ${TRACK_THUMB_SCALE_ANIMATION_DURATION}ms linear`,
    //     }}
    //   /> */}
    // </div>
  );
}

type ArtistInfoBurstProps = {
  id: string;
  userId: string;
  title: string;
  username: string;
  titleSize?: number | string;
  usernameSize?: number | string;
  withBurst?: boolean;
};

export function ArtistInfoBurst({
  id,
  userId,
  title,
  username,
  titleSize,
  usernameSize,
  withBurst,
}: ArtistInfoBurstProps) {
  // const { openProfile } = useNavigationFunctions();
  const text1 = title ? [title] : undefined;
  const text2 = username ? [`@${username}`] : undefined;
  // const bottomLeftHandlers = [() => openProfile({ id: userId })];
  return (
    <BifurcatedListItem
      topLeft={text1}
      // topLeftMarqueeId={id}
      topLeftStyles={[{ fontSize: titleSize ?? TITLE_FONT_SIZE }]}
      bottomLeft={text2}
      bottomLeftHandlers={[() => {}]}
      // bottomLeftMarqueeId={id}
      bottomLeftStyles={[{ fontSize: usernameSize ?? USERNAME_FONT_SIZE }]}
      rightElement={withBurst ? <BurstSwitch /> : null}
      textPadding={0}
    />
  );
}

type TimeDisplayProps = {
  progress: number;
  duration: number;
};

function TimeDisplay({ progress, duration }: TimeDisplayProps) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: TIME_STRING_MARGIN,
      }}
    >
      <span
        style={{
          fontFamily: sfProTextSemibold,
          fontSize: TIME_FONT_SIZE,
          color: INACTIVE_COLOR,
        }}
      >
        {createTimestampString(progress)}
      </span>
      <span
        style={{
          fontFamily: sfProTextSemibold,
          fontSize: TIME_FONT_SIZE,
          color: ACTIVE_COLOR,
        }}
      >
        {createTimestampString(duration)}
      </span>
    </div>
  );
}
