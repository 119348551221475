import {
  getSongMap,
  pushSongs,
  setSongs,
} from "../../redux/interfaces/contentInterface";
import { getMyUser, updateMyUser } from "../../redux/interfaces/userInterface";
import { addScoreToLeaderboard } from "../../utilities/leaderboard";
import { voteMutation } from "../query/mutations";
import { executeQuery } from "../query/queryUtils";
import { logEvent } from "../../utilities/statsig";
import { shortHaptic } from "../../utilities/haptics";

const votesMap: { [songID: string]: VoteState } = {};

function getVoteState(songID: string) {
  const song = getSongMap()?.[songID];
  const songVoteState = votesMap[songID] ?? song?.myVote?.type;
  return songVoteState;
}

export function hasVotedState(myVote: Vote | undefined) {
  return (
    !!myVote?.type && myVote?.type !== "NONE" && myVote?.type !== "UNKNOWN"
  );
}

export async function voteAndUpdateSongData(songObj: Song, type: VoteState) {
  const myUserID = getMyUser()?.id;
  const songID = songObj?.id;
  const songUpdate = simulateVote(songID, type);
  shortHaptic();
  if (!songUpdate) return;
  if (myUserID) {
    if (type === "UPVOTE")
      pushSongs([songUpdate], [], myUserID, { prepend: true });
    else pushSongs([songUpdate], [], myUserID, { remove: true });
  }
  logEvent("VOTE", type, {
    title: songObj?.title,
    id: songObj?.id,
    artist: songObj?.artist?.username,
  });

  const response = await executeQuery(voteMutation(songID, type));
  const { code, success, message, vote } = response?.data?.vote ?? {};
  // console.re.log("vote response", response);
  if (!success || !vote) return false;
  const { song, voter } = vote as Vote;
  if (!song || !voter) return false;
  const { score } = voter ?? {};
  addScoreToLeaderboard(score ?? 99);
  const { id, ranks } = song;
  // setSongs([{ id, ranks }]);
  // pushSongs([song], [], id, { prepend: true });
  updateMyUser(voter);
}

export async function setSongSeen(songID: string) {
  const currVoteState = getVoteState(songID);
  if (!currVoteState || currVoteState === "UNKNOWN") {
    votesMap[songID] = "NONE";
    await executeQuery(voteMutation(songID, "NONE"));
  }
}

export function simulateVote(songID: string, type: VoteState): Song | null {
  votesMap[songID] = type;
  const currentSong = getSongMap()?.[songID];
  const prevType = currentSong?.myVote?.type;
  let upvoteDelta = 0;
  let downvoteDelta = 0;
  switch (type) {
    case "UPVOTE":
      if (prevType === "DOWNVOTE") downvoteDelta -= 1;
      if (prevType !== "UPVOTE") upvoteDelta += 1;
      break;
    case "DOWNVOTE":
      if (prevType === "UPVOTE") upvoteDelta -= 1;
      if (prevType !== "DOWNVOTE") downvoteDelta += 1;
      break;
    case "NONE":
      if (prevType === "UPVOTE") upvoteDelta -= 1;
      if (prevType === "DOWNVOTE") downvoteDelta -= 1;
      break;
  }

  if (upvoteDelta + downvoteDelta === 0)
    return { id: songID, myVote: { type } };
  const votesNum = currentSong?.votesNum ?? { upvotes: 0, downvotes: 0 };
  votesNum.upvotes += upvoteDelta;
  votesNum.downvotes += downvoteDelta;
  votesNum.upvotes = Math.max(votesNum.upvotes, 0);
  votesNum.downvotes = Math.max(votesNum.downvotes, 0);
  return { id: songID, votesNum, myVote: { type } };
}
