import { ReactNode } from "react";
import { grey2, purple, red, white, yellow } from "../../utilities/colors";
import { sfProTextRegular, sfProTextSemibold } from "../../utilities/fonts";
import { scale } from "../../utilities/scaling";
import ClickableOpacityDiv from "../clickableOpacityDiv/ClickableOpacityDiv";
import MarqueeSpan from "../marqueeSpan/MarqueeSpan";

export type BifurcatedListItemProps = {
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  bottomElement?: ReactNode;
  topLeft?: ReactNode | string[];
  topLeftMarqueeId?: string;
  topLeftStyles?: (Style | undefined)[];
  topLeftOverallStyle?: Style;
  topLeftHandlers?: (() => void)[];
  bottomLeft?: ReactNode | string[];
  bottomLeftMarqueeId?: string;
  bottomLeftStyles?: (Style | undefined)[];
  bottomLeftOverallStyle?: Style;
  bottomLeftHandlers?: (() => void)[];
  topRight?: ReactNode | string[];
  topRightStyles?: (Style | undefined)[];
  bottomRight?: ReactNode | string[];
  textPadding?: number;
  verticalPadding?: number;
  bottomRightStyles?: (Style | undefined)[];
  style?: Style;
  leftElementWrapperStyle?: Style;
  rightElementWrapperStyle?: Style;
  bottomElementWrapperStyle?: Style;
  withOpacity?: boolean;
  onClick?: () => void;
  noOpacity?: boolean;
  adjustSize?: number;
};
export const LEFT_LIST_ITEM_IMAGE_SIZE = 50;
export const RIGHT_LIST_ITEM_IMAGE_SIZE = 40;
export const RIGHT_LIST_ITEM_IMAGE_BORDER_RADIUS =
  RIGHT_LIST_ITEM_IMAGE_SIZE / 8;
export const BASE_HEIGHT = 50;
export const ELLIPSES_SIZE = 25;
export const MARGIN = 10;

export const TEXT_BOTTOM_MARGIN = 2;

export const LARGE_FONT_SIZE = 17;
export const MEDIUM_FONT_SIZE = 15;

export default function BifurcatedListItem({
  leftElement,
  rightElement,
  bottomElement,
  topLeft,
  topLeftOverallStyle,
  topLeftMarqueeId,
  bottomLeft,
  bottomLeftMarqueeId,
  bottomLeftOverallStyle,
  topLeftStyles,
  topLeftHandlers,
  bottomLeftStyles,
  bottomLeftHandlers,
  topRightStyles,
  bottomRightStyles,
  topRight,
  bottomRight,
  textPadding,
  verticalPadding,
  leftElementWrapperStyle,
  rightElementWrapperStyle,
  bottomElementWrapperStyle,
  style,
  onClick,
  noOpacity,
  adjustSize,
}: BifurcatedListItemProps) {
  const adjustedScale = adjustSize ?? 1;
  const textBottomMargin = TEXT_BOTTOM_MARGIN * adjustedScale;
  const margin = MARGIN * adjustedScale;
  const largeFontSize = LARGE_FONT_SIZE * adjustedScale;
  const mediumFontSize = MEDIUM_FONT_SIZE * adjustedScale;

  const TopLeftSpan = topLeftMarqueeId ? MarqueeSpan : Span;

  const topLeftElement = Array.isArray(topLeft) ? (
    <TopLeftSpan
      {...(topLeftMarqueeId ? { marqueeId: topLeftMarqueeId } : {})}
      style={{
        ...{
          display: "block",
          marginBottom: bottomLeft || bottomRight ? textBottomMargin : 0,
          flexWrap: "nowrap",
          whiteSpace: "nowrap",
          color: white,
        },
        ...topLeftOverallStyle,
      }}
    >
      {topLeft.map((text: string, index: number) => (
        <span
          key={index}
          style={{
            ...{
              fontFamily: sfProTextSemibold,
              fontSize: bottomLeft ? mediumFontSize : largeFontSize,
            },
            ...(topLeftStyles?.[index] ? topLeftStyles?.[index] : {}),
          }}
        >
          {`${index ? " " : ""}${text}`}
        </span>
      ))}
    </TopLeftSpan>
  ) : (
    topLeft
  );

  const topRightElement = Array.isArray(topRight) ? (
    <span
      style={{
        display: "block",
        marginBottom: bottomLeft || bottomRight ? textBottomMargin : undefined,
        flexWrap: "nowrap",
        whiteSpace: "nowrap",
        color: grey2,
      }}
    >
      {topRight.map((text: string, index: number) => (
        <span
          key={index}
          style={{
            ...{
              fontFamily: sfProTextRegular,
              fontSize: mediumFontSize,
              color: grey2,
            },
            ...(topRightStyles?.[index] ? topRightStyles?.[index] : {}),
          }}
        >
          {`${index ? " " : ""}${text}`}
        </span>
      ))}
    </span>
  ) : (
    topRight
  );

  const BottomLeftSpan = bottomLeftMarqueeId ? MarqueeSpan : Span;

  const bottomLeftElement = Array.isArray(bottomLeft) ? (
    <BottomLeftSpan
      {...(bottomLeftMarqueeId ? { marqueeId: bottomLeftMarqueeId } : {})}
      style={{
        ...{
          display: "block",
          flexWrap: "nowrap",
          whiteSpace: "nowrap",
          color: grey2,
          textOverflow: "ellipses",
          overflow: "hidden",
        },
        ...bottomLeftOverallStyle,
      }}
    >
      {bottomLeft.map((text: string, index: number) => (
        <span
          key={index}
          onClick={bottomLeftHandlers?.[index]}
          style={{
            ...{
              fontFamily: sfProTextRegular,
              fontSize: mediumFontSize,
              color: grey2,
            },
            ...(bottomLeftStyles?.[index] ? bottomLeftStyles?.[index] : {}),
          }}
        >
          {`${index ? " " : ""}${text}`}
        </span>
      ))}
    </BottomLeftSpan>
  ) : (
    bottomLeft
  );

  const bottomRightElement = Array.isArray(bottomRight) ? (
    <span
      style={{
        display: "block",
        flexWrap: "nowrap",
        whiteSpace: "nowrap",
        color: grey2,
      }}
    >
      {bottomRight.map((text: string, index: number) => (
        <span
          key={index}
          style={{
            ...{
              fontFamily: sfProTextRegular,
              fontSize: mediumFontSize,
              color: grey2,
            },
            ...(bottomRightStyles?.[index] ? bottomRightStyles?.[index] : {}),
          }}
        >
          {`${index ? " " : ""}${text}`}
        </span>
      ))}
    </span>
  ) : (
    bottomRight
  );

  const handleOnClick = onClick ? () => onClick() : undefined;

  return (
    <ClickableOpacityDiv
      withOpacity={!!onClick && !noOpacity}
      style={{
        ...{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          padding: verticalPadding ?? margin,
          paddingLeft: 0,
          paddingRight: 0,
          boxSizing: "border-box",
          // overflow: "hidden",
        },
        ...style,
      }}
      disabled={!onClick}
      onClick={handleOnClick}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          overflow: "hidden",
        }}
      >
        {leftElement ? (
          <div
            style={{
              ...{ paddingLeft: margin, flexShrink: 0 },
              ...leftElementWrapperStyle,
            }}
          >
            {leftElement}
          </div>
        ) : null}

        <div
          style={{
            position: "relative",
            height: "100%",
            flex: "1 1 100%",
            flexDirection: "column",
            // overflow: "hidden",
            overflowY: "visible",
            overflowX: "clip",
            overflow: "hidden",
            marginLeft: textPadding ?? margin,
            marginRight: textPadding ?? margin,
          }}
        >
          <div
            style={{
              position: "relative",
              height: bottomRightElement || bottomLeftElement ? "50%" : "100%",
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              alignItems:
                bottomLeftElement || bottomRightElement ? "flex-end" : "center",
              overflowY: "visible",
              overflowX: "clip",
              overflow: "hidden",
            }}
          >
            <div style={{ flex: "0 1 auto", marginLeft: margin / 2 }}>
              {topRightElement}
            </div>
            <div
              style={{
                position: "relative",
                flexGrow: 1,
                overflow: "hidden",
              }}
            >
              {topLeftElement}
            </div>
          </div>
          {bottomRightElement || bottomLeftElement ? (
            <div
              style={{
                position: "relative",
                height: "50%",
                width: "100%",
                display: "flex",
                flexDirection: "row-reverse",
                alignItems:
                  topLeftElement || topRightElement ? "flex-start" : "center",
                overflow: "hidden",
              }}
            >
              <div style={{ flex: "0 1 auto", marginLeft: margin / 2 }}>
                {bottomRightElement}
              </div>
              <div
                style={{
                  position: "relative",
                  flexGrow: 1,
                  overflow: "hidden",
                }}
              >
                {bottomLeftElement}
              </div>
            </div>
          ) : null}
        </div>
        {rightElement ? (
          <div
            style={{
              ...{ paddingRight: margin * 1.5, flexShrink: 0, height: "100%" },
              ...rightElementWrapperStyle,
            }}
          >
            {rightElement}
          </div>
        ) : null}
      </div>
      {bottomElement ? (
        <div
          style={{
            ...{
              marginTop: margin,
              position: "relative",
              flexGrow: 1,
              flexShrink: 0,
            },
            ...bottomElementWrapperStyle,
          }}
        >
          {bottomElement}
        </div>
      ) : null}
    </ClickableOpacityDiv>
  );
}

function Span(props: SpanProps) {
  const { style, children, ...spanProps } = props;

  return (
    <span
      style={{
        ...{
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
        ...style,
      }}
      {...spanProps}
    >
      <span
        style={{
          display: "inline",
        }}
      >
        {children}
      </span>
    </span>
  );
}
