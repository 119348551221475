import BurstSwitch from "../../../songPlayer/components/songPlayerPage/components/songPlayerPageOverlay/components/songPageOverlayFooter/components/burstSwitch/BurstSwitch";
import { standardMargin } from "../../../utilities/scaling";
type Props = { image?: string; isVertical?: boolean; size: number };

export default function SongSquareImage({ image, isVertical, size }: Props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!!isVertical && <BurstSwitch height={30} />}

      <div
        style={{
          // width: size,
          // height: size,
          width: "80%",
          maxWidth: isVertical ? size * 0.45 : undefined,
          maxHeight: isVertical ? size * 0.45 : undefined,
          aspectRatio: "1/1",
          borderRadius: "4px",
          overflow: "hidden",
          marginTop: isVertical ? standardMargin : undefined,
        }}
      >
        <img
          style={{ width: "100%", height: "100%" }}
          src={image}
          alt={"songImage"}
        />
      </div>
    </div>
  );
}
