import { useEffect, useState } from "react";

export type ScaleOptionsType = {
  type?: ScaleType;
  round?: RoundType;
  curve?: boolean;
  minimumValue?: number;
  maximumValue?: number;
};
export type ScaleType = "EVEN" | "VERTICAL" | "HORIZONTAL";
export type RoundType = "CEIL" | "FLOOR" | "ROUND";

const GALAXY_S20_ULTRA_HEIGHT = 915;
const GALAXY_S20_ULTRA_WIDTH = 412;

const getDims = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

function useDims() {
  const [dims, setDims] = useState<{ height: number; width: number }>(
    getDims(),
  );

  useEffect(() => {
    setDims(getDims());
    window.addEventListener("resize", () => setDims(getDims()));
    return () => window.removeEventListener("resize", () => setDims(getDims()));
  }, []);

  return dims;
}

const curveScaleFactor = (number: number, scaledNumber: number) => {
  const difference = scaledNumber - number;
  const curvedNumber = number + difference / 2;
  return curvedNumber;
};

const roundNumber = (number: number, type?: RoundType) => {
  if (!type) return number;
  switch (type) {
    case "CEIL":
      return Math.ceil(number);
    case "FLOOR":
      return Math.floor(number);
    case "ROUND":
      return Math.round(number);
  }
};

const scaleNumber = (number: number, type?: ScaleType) => {
  const { width, height } = getDims();
  switch (type) {
    case "VERTICAL":
      return (number * height) / GALAXY_S20_ULTRA_HEIGHT;
    case "HORIZONTAL":
      return (number * width) / GALAXY_S20_ULTRA_WIDTH;
    default:
      return (
        number *
        Math.sqrt(
          (width * height) / (GALAXY_S20_ULTRA_HEIGHT * GALAXY_S20_ULTRA_WIDTH),
        )
      );
  }
};

const scale = (number: number, options?: ScaleOptionsType) => {
  let scaledNumber = scaleNumber(number, options?.type);
  if (!options) return scaledNumber;
  const { round, curve, minimumValue, maximumValue } = options;
  if (curve) scaledNumber = curveScaleFactor(number, scaledNumber);
  if (round) scaledNumber = roundNumber(scaledNumber, round);
  if (minimumValue) scaledNumber = Math.max(scaledNumber, minimumValue);
  if (maximumValue) scaledNumber = Math.min(scaledNumber, maximumValue);
  return scaledNumber;
};

const standardMargin = scale(16, { type: "VERTICAL" });

export { getDims, useDims, scale, standardMargin };
