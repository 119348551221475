import ClickableOpacityDiv from "../../../../components/clickableOpacityDiv/ClickableOpacityDiv";
import { hasVotedState } from "../../../../controlModule/fetchFunctions/voteFunctions";
import { PlaylistPagePropsType } from "../../../../screens/playlistPage/PlaylistPage";
import { grey6, opacity, white } from "../../../../utilities/colors";
import { sfProTextRegular } from "../../../../utilities/fonts";
import { useNavigationFunctions } from "../../../../utilities/navigation";
import { scale, standardMargin } from "../../../../utilities/scaling";

import TagRank from "../../../../songPlayer/components/songPlayerPage/components/songPlayerPageOverlay/components/songPageOverlayFooter/components/tags/components/TagRank";

const FONT_SIZE = 18;
const TAG_PADDING_HORIZONTAL = FONT_SIZE * 0.6;
const TAG_BORDER_RADIUS = FONT_SIZE * 0.8;
const TAG_PADDING_TOP = FONT_SIZE * 0.2;
export const TAG_PADDING_BOTTOM = FONT_SIZE * 0.2;

export type Props = { song: Song };

export default function SongPageTags({ song }: Props) {
  const { myVote, rankMap } = song ?? {};

  return (
    <div
      style={{
        paddingLeft: standardMargin,
        paddingRight: standardMargin,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      {song?.tags?.map((tag: string, index: number) => {
        const rank = rankMap?.[tag];
        // if (!rank) return null;
        return (
          <Tag
            key={tag + index}
            tag={tag}
            rank={rank}
            openPlaylist={() => {}}
            hasVoted={hasVotedState(myVote)}
            index={index}
          />
        );
      })}
    </div>
  );
}

type TagProps = {
  tag: string;
  rank?: number;
  openPlaylist: (props: PlaylistPagePropsType) => void;
  hasVoted?: boolean;
  index: number;
};

function Tag({ tag, rank, openPlaylist, hasVoted, index }: TagProps) {
  return (
    <ClickableOpacityDiv
      disabled
      onClick={() => openPlaylist({ id: tag })}
      style={{
        paddingTop: TAG_PADDING_TOP,
        paddingBottom: TAG_PADDING_BOTTOM,
        paddingLeft: TAG_PADDING_HORIZONTAL,
        paddingRight: TAG_PADDING_HORIZONTAL,
        borderRadius: TAG_BORDER_RADIUS,
        background: opacity(white, 0.15),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: standardMargin / 2,
        marginTop: standardMargin * 0.8,
        position: "relative",
      }}
    >
      <span
        style={{
          fontSize: FONT_SIZE,
          color: white,
          fontFamily: sfProTextRegular,
        }}
      >
        {`#${tag}`}
      </span>
    </ClickableOpacityDiv>
  );
}
