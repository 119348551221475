import fwaygoPng from "../../../assets/pngs/fwaygo.png";
import {
  black,
  blue,
  green,
  opacity,
  red,
  white,
} from "../../../utilities/colors";
import {
  azonix,
  sfProTextRegular,
  sfProTextSemibold,
} from "../../../utilities/fonts";
import appleAppStoreLogoPng from "../../../assets/pngs/appleAppStoreLogo.png";
import ClickableOpacityDiv from "../../../components/clickableOpacityDiv/ClickableOpacityDiv";
import { openURLInNewTab } from "../../../utilities/utilities";
import { useScale } from "../../../utilities/useScale";

type Props = {};

export default function FwaygoAd({}: Props) {
  const { scale } = useScale();

  const height = scale(80);

  const fontSizeLarge = scale(16);

  // const fontSizeFwaygo = scale(24);
  // const fontSizeFwaygoLineHeight = fontSizeFwaygo * 0.9;
  const fwaygoLogoHeight = scale(20);

  const fontSizeSmall = scale(8);

  const buttonHeight = scale(22);
  const buttonWidth = buttonHeight * 2;
  const appleImageHeight = buttonHeight * 0.5;
  const borderRadius = 999;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-between",
        height,
      }}
    >
      <span
        style={{
          fontFamily: sfProTextSemibold,
          color: white,
          fontSize: fontSizeLarge,
        }}
      >
        {"Download"}
      </span>
      <img
        style={{ height: fwaygoLogoHeight, marginBottom: scale(2) }}
        src={fwaygoPng}
      />

      {/* <span
        style={{
          fontFamily: sfProTextRegular,
          color: white,
          fontSize: fontSizeSmall,
        }}
      >
        on the App Store
      </span> */}
      <ClickableOpacityDiv
        withOpacity
        onClick={() => {
          openURLInNewTab("https://apps.apple.com/us/app/fwaygo/id1563957143");
          // sc.presentWebpage(url);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: buttonHeight,
          width: buttonWidth,
          borderRadius: borderRadius,
          borderWidth: 2,
          borderColor: opacity(white, 0.8),
          borderStyle: "solid",
          boxSizing: "border-box",
        }}
      >
        <img style={{ height: appleImageHeight }} src={appleAppStoreLogoPng} />
      </ClickableOpacityDiv>
    </div>
  );
}
