import Navigator from "./navigation/Navigator";
import { Provider } from "react-redux";
import { store } from "./redux/useRedux";
import "./index.scss";
import WebPlayer from "./webplayer/WebPlayer";
import { useEffect, useState } from "react";
import { authUser } from "./users/auth";
import LoadingScreen from "./components/loadingScreen/LoadingScreen";

export type Props = {};

export default function App(props: Props) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    authUser("test-user-id").then(() => {
      setIsLoading(false);
    });
  }, []);
  if (isLoading) return <LoadingScreen />;
  return (
    <Provider store={store}>
      {/* <Navigator /> */}
      <WebPlayer />
    </Provider>
  );
}
