import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DISCOVER_FEED_ID } from "../../constants";
import {
  assessQ,
  loadAndPlaySong,
  selectAudioFromPlaylist,
  selectDiscoverFeed,
} from "../../controlModule/trackController";
import sc from "@snapchat/snapcanvas-sdk";
import {
  createEventListener,
  removeEventListener,
} from "../../utilities/events";
import { topEntriesID } from "../../utilities/mutableAppData";

export default function useFeedFetcherWP() {
  const currPlaylistID = useSelector(
    (state: AppState) => state.content.currPlaylist,
  );
  const currIndex = useSelector((state: AppState) => state.content.currIndex);
  const playlists = useSelector((state: AppState) => state.content.playlists);

  const firstPlayEventDone = useRef(false);

  function firstOpenSongPlayer() {
    sc.loadingComplete();
    // openSongPlayer();
  }

  const isShareRoutingDone = useRef(false);
  const [shareRoutingSongID, setShareRoutingSongID] = useState<
    string | null | undefined
  >();
  const shareRoutingPlaylist = useSelector(
    (state: AppState) => state.content.playlists?.[shareRoutingSongID ?? ""],
  );
  const discoverPlaylist = useSelector(
    (state: AppState) => state.content.playlists?.[DISCOVER_FEED_ID],
  );
  const currentPlaylist = useSelector(
    (state: AppState) => state.content.playlists?.[currPlaylistID],
  );

  const [loadDiscoverFailed, setLoadDiscoverFailed] = useState(0);

  const shareInfoRouting = async () => {
    const url = new URL(window.location.toLocaleString());
    const songID = url.searchParams.get("song");
    const shareId: string = songID ?? "isngiE1rpuHB0HnU6hICDxud";

    if (!isShareRoutingDone.current) {
      isShareRoutingDone.current = true;
      if (shareId.includes("isng")) {
        console.log("Fetching");
        setShareRoutingSongID(shareId ?? "NONE");
        loadAndPlaySong(shareId);
        // logEvent("FOLLOW SONG LINK", shareId, shareInfo.shareInfo);
      } else if (shareId.includes("iusr")) {
        // loadPlaylist(shareId);
        // openProfile({ id: shareId });
        setShareRoutingSongID(shareId);
        // logEvent("FOLLOW USER LINK", shareId, shareInfo.shareInfo);
      }
    } else if (!isShareRoutingDone.current) {
      isShareRoutingDone.current = true;
      setShareRoutingSongID(null);
    }
  };

  useEffect(() => {
    shareInfoRouting();
    setTimeout(() => {
      if (!isShareRoutingDone.current) shareInfoRouting();
    }, 500);
    createEventListener("VIDEO_SKIPPED", () => {
      if (!isShareRoutingDone.current) {
        shareInfoRouting();
      }
    });
    return () => {
      removeEventListener("VIDEO_SKIPPED", () => {});
    };
  }, []);

  useEffect(() => {
    //TODO: figure out why this line was even here
    if (shareRoutingSongID === undefined) return;
    assessQ();
  }, [currPlaylistID, currentPlaylist, currIndex, shareRoutingSongID]);

  useEffect(() => {
    if (
      discoverPlaylist &&
      !firstPlayEventDone.current &&
      shareRoutingSongID === null
    ) {
      firstPlayEventDone.current = true;
      const success = selectDiscoverFeed();
      // console.re.log("selectDiscoverFeed success", success);
      if (!success) setLoadDiscoverFailed(1);
      else firstOpenSongPlayer();
    }
  }, [discoverPlaylist, shareRoutingSongID]);

  const retriedFallback = useRef(false);
  useEffect(() => {
    //Attempt fallback to top Entries
    if (
      loadDiscoverFailed === 1 &&
      playlists[topEntriesID]?.length &&
      !retriedFallback.current
    ) {
      retriedFallback.current = true;
      const success = selectDiscoverFeed();
      // console.re.log("selectDiscoverFeed fallback success", success);
      if (success) firstOpenSongPlayer();
      setLoadDiscoverFailed(2);
    }
  }, [loadDiscoverFailed, playlists]);

  useEffect(() => {
    if (
      shareRoutingSongID &&
      shareRoutingPlaylist?.length &&
      !firstPlayEventDone.current
    ) {
      firstPlayEventDone.current = true;
      selectAudioFromPlaylist(shareRoutingSongID, 0);
      firstOpenSongPlayer();
    }
  }, [shareRoutingSongID, shareRoutingPlaylist]);

  //Load initial playlist pages

  const [targetSongID, setTargetSongID] = useState<string | undefined>();
  const targetPlaylist = useSelector(
    (state: AppState) => state.content?.playlists?.[targetSongID ?? ""],
  );
  const targetSong = useSelector(
    (state: AppState) => state.content?.songMap?.[targetSongID ?? ""],
  );

  useEffect(() => {
    createEventListener("setTargetSongID", (data: string) => {
      setTargetSongID(data);
    });
    return () => {
      removeEventListener("setTargetSongID", () => {});
    };
  }, []);

  useEffect(() => {
    if (targetSongID && targetPlaylist?.length && targetSong) {
      selectAudioFromPlaylist(targetSongID, 0);
      setTargetSongID(undefined);
    }
  }, [targetSongID, targetPlaylist, targetSong]);

  return {};
}
