import { copyText, openURLInNewTab } from "../../utilities/utilities";
import appleMusicCirclePng from "../../assets/pngs/socialIcons/apple.png";
import tiktokCirclePng from "../../assets/pngs/socialIcons/tiktok.png";
import twitterCirclePng from "../../assets/pngs/socialIcons/twitter.png";
import instagramCirclePng from "../../assets/pngs/socialIcons/instagram.png";
import fwaygoCirclePng from "../../assets/pngs/socialIcons/fwaygo.png";
import spotifyCirclePng from "../../assets/pngs/socialIcons/spotify.png";
import ClickableOpacityDiv from "../clickableOpacityDiv/ClickableOpacityDiv";
import { DEEPLINK_DOMAIN } from "../../constants";
import { logEvent } from "../../utilities/statsig";
import sc from "@snapchat/snapcanvas-sdk";
import { showToast } from "../../utilities/toast";

type Props = {
  spotifyURL: string | undefined;
  appleMusicURL: string | undefined;
  soundcloudURL: string | undefined;
  tiktokURL: string | undefined;
  instagramURL: string | undefined;
  twitterURL: string | undefined;
  userId: string;
  songId?: string;
  songImage?: string;
  size: number;
  spacing: number;
  name: string;
};

export type ServiceButtonProps = {
  buttonHeight: number;
};

export default function SocialMediaRow({
  spotifyURL,
  appleMusicURL,
  soundcloudURL,
  tiktokURL,
  instagramURL,
  twitterURL,
  userId,
  songId,
  songImage,
  size,
  spacing,
  name,
}: Props) {
  const shareUrl = `https://${DEEPLINK_DOMAIN}?endpoint=profile&id=${userId}`;

  const serviceButtons: {
    url?: string;
    image: string;
    name: string;
  }[] = [
    {
      name: "Spotify",
      url: spotifyURL,
      image: spotifyCirclePng,
    },
    {
      name: "Apple Music",
      url: appleMusicURL,
      image: appleMusicCirclePng,
    },
    {
      name: "Tik Tok",
      url: tiktokURL,
      image: tiktokCirclePng,
    },
    {
      name: "Instagram",
      url: instagramURL,
      image: instagramCirclePng,
    },
    {
      name: "Twitter",
      url: twitterURL,
      image: twitterCirclePng,
    },
    {
      name: "FWAYGO",
      url: shareUrl,
      image: fwaygoCirclePng,
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {serviceButtons.map(({ url, image, name }) =>
        url ? (
          <ClickableOpacityDiv
            key={name}
            withOpacity
            style={{
              marginRight: spacing,
              height: size,
              width: size,
              borderRadius: "50%",
            }}
            onClick={() => {
              console.log("SOCIAL URL CLICK", name, { url: url, name: name });
              openURLInNewTab(url);
              // sc.presentWebpage(url);
            }}
          >
            <img
              style={{
                height: size,
                width: size,
                borderRadius: "50%",
                opacity: 0.9,
              }}
              src={image}
              //alt={"socialImage"}
            />
          </ClickableOpacityDiv>
        ) : null,
      )}
    </div>
  );
}
