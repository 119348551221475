import playPng from "../../../../assets/pngs/playPause/Play.png";
import pausePng from "../../../../assets/pngs/playPause/Pause.png";
import skipForwardPng from "../../../../assets/pngs/SkipForward.png";
import skipBackPng from "../../../../assets/pngs/SkipBack.png";
import { useEffect, useState } from "react";
import {
  createEventListener,
  removeEventListener,
} from "../../../../utilities/events";
import { scale, standardMargin } from "../../../../utilities/scaling";
import {
  selectNextCurrentPlaylist,
  selectPrevCurrentPlaylist,
  togglePlayPause,
} from "../../../../controlModule/trackController";
import ClickableOpacityDiv from "../../../../components/clickableOpacityDiv/ClickableOpacityDiv";

type Props = {};

const BUTTON_HEIGHT = 50;
const MARGIN = 40;

export default function SongPageControls({}: Props) {
  const [playState, setPlayState] = useState<PlayState>("pause");

  function toggleFunc() {
    const state = togglePlayPause();
  }

  useEffect(() => {
    const handleEvent = ({ type }: any) => {
      setPlayState(type);
    };
    createEventListener("playback-state", handleEvent);
    return () => {
      removeEventListener("playback-state", handleEvent);
    };
  }, []);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <ClickableOpacityDiv
        withOpacity
        onClick={() => {
          selectPrevCurrentPlaylist();
        }}
      >
        <img src={skipBackPng} style={{ height: BUTTON_HEIGHT }} />
      </ClickableOpacityDiv>
      <ClickableOpacityDiv
        style={{
          marginLeft: MARGIN,
          marginRight: MARGIN,
        }}
        withOpacity
        onClick={toggleFunc}
      >
        <img
          src={playState === "playing" ? pausePng : playPng}
          style={{ height: BUTTON_HEIGHT }}
        />
      </ClickableOpacityDiv>
      <ClickableOpacityDiv
        withOpacity
        onClick={() => {
          selectNextCurrentPlaylist();
        }}
      >
        <img src={skipForwardPng} style={{ height: BUTTON_HEIGHT }} />
      </ClickableOpacityDiv>
    </div>
  );
}
