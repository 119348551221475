import {
  black,
  darkerGreyBackground,
  opacity,
  white,
} from "../../utilities/colors";
import Loading from "../loading/Loading";

type Props = {};

export default function LoadingScreen(props: Props) {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: darkerGreyBackground,
        // opacity: 0.9,
        zIndex: 5,
      }}
    >
      <Loading pulse />
    </div>
  );
}
