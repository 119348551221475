import { MouseEvent, TouchEvent } from "react";
import "./clickableOpacityDiv.scss";

type ExtraProps = {
  withOpacity?: boolean;
  draggable?: boolean;
  disabled?: boolean;
  onClick?: (e?: MouseEvent<HTMLDivElement>) => void;
};

type Props = DivProps & ExtraProps;

const preventBubbling = (e: any) => {
  e.stopPropagation();
  e.preventDefault();
};

export default function ClickableOpacityDiv(props: Props) {
  const { withOpacity, draggable, disabled, style, onClick, ...rest } = props;

  const handleClick = onClick
    ? (e: MouseEvent<HTMLDivElement>) => {
        preventBubbling(e);
        onClick(e);
      }
    : undefined;

  return (
    <div
      onClick={disabled ? undefined : handleClick}
      id={withOpacity && !disabled ? "clickableOpacityDiv" : undefined}
      style={{
        ...{
          pointerEvents: disabled ? "none" : "auto",
        },
        ...style,
      }}
      {...rest}
    />
  );
}
