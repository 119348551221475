import "@firebase/auth";
import "@firebase/firestore";
import { 
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_APP_ID,
  REACT_APP_MEASUREMENT_ID } from "./constants";

const firebaseConfig = {
  projectId: REACT_APP_FIREBASE_PROJECT_ID || "",
  apiKey: REACT_APP_FIREBASE_API_KEY || "",
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN || "",
  databaseURL: "",
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET || "",
  messagingSenderId: "",
  appId: REACT_APP_APP_ID || "",
  measurementId: REACT_APP_MEASUREMENT_ID || "",
  trackingId: "",
};

export { firebaseConfig };
