import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import SnapCanvasSDK from "./SnapCanvasSDK";
import consolere from "console-remote-client";
import "./index.scss";
import firebase, { initializeApp, getApps } from "firebase/app";
import { firebaseConfig } from "./config";
import { REACT_APP_APP_ID } from "./constants";
import LoadingScreen from "./components/loadingScreen/LoadingScreen";

// consolere.connect({
//   server: "https://console.re",
//   channel: "fwaygo-mini",
//   redirectDefaultConsoleToRemote: false,
//   disableDefaultConsoleOutput: true,
// });

export var firebaseApp = {} as firebase.FirebaseApp;
if (!getApps().length) {
  firebaseApp = initializeApp(firebaseConfig);
}

const container = document.getElementById("root");
const root = createRoot(container!);
const initParams = {
  applicationId: REACT_APP_APP_ID,
};
const loading = <LoadingScreen />;

root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);

(function () {
  console.error = () => null;
  console.log = () => null;
  console.warn = () => null;
  console.info = () => null;
  console.group = () => null;
  console.groupEnd = () => null;
  console.time = () => null;
  console.timeEnd = () => null;
})();

(function () {
  if (!console.re) return;
  console.re.error = () => null;
  console.re.log = () => null;
  console.re.warn = () => null;
})();

reportWebVitals();
