const initialState: MyUserType = { id: "" };

export function userReducer(
  state: MyUserType = initialState,
  action: MyUserActions,
): MyUserType {
  switch (action.type) {
    case "UPDATE_MY_USER":
      const newState = { ...state, ...action.user };
      console.log("UPDATE_MY_USER", action.user, newState);
      return newState;
    default:
      return state;
  }
}
