import {
  getContentData,
  getFriendIDList,
  getPlaylists,
  getPlaylistSet,
  getUserMap,
  pushSongs,
  setPlaylistsMetadata,
} from "../../redux/interfaces/contentInterface";
import { DISCOVER_FEED_ID } from "../../constants";
import {
  communityFavoritesQuery,
  mutualDiscoveriesQuery,
  chartByTagQuery,
  userSongsQuery,
  userVotesQuery,
  featuredPlaylistsQuery,
  playlistQuery,
  snapchatFeedQuery,
  getFullSongQuery,
  featuredTagsQuery,
  top40Query,
} from "../query/queries";
import {
  executeQuery,
  extractUsersFromSongs,
  processCursor,
} from "../query/queryUtils";
import { getMyUser } from "../../redux/interfaces/userInterface";
import { getPlaylistData, tagPlaylists } from "../../utilities/mutableAppData";
import { voteMutation } from "../query/mutations";

const DEFAULT_LIMIT = 10;
const playlistCursorMap: { [playlistID: string]: string | undefined } = {};
const playlistFetchingMap: { [playlistID: string]: boolean } = {};

export function isPlaylistLoaded(playlistID: string) {
  return playlistCursorMap[playlistID];
}

export function hasMoreSongs(playlistID: string) {
  const hasMore = !(playlistCursorMap?.[playlistID] === "");
  return hasMore;
}

function clearCursor(playlistID: string) {
  delete playlistCursorMap[playlistID];
}

async function fetchChart(playlistID: string, limit?: number) {
  const query = chartByTagQuery(
    playlistID,
    limit ?? DEFAULT_LIMIT,
    playlistCursorMap[playlistID],
  );
  var start = Date.now();
  console.log("fetchChart called");
  //TODO: Error handle
  const response = await executeQuery(query);

  const { songs, next } = response?.data?.chartByTag ?? {};
  console.log("fetchChart result", Date.now() - start, response, songs, next);
  playlistCursorMap[playlistID] = processCursor(next);
  return songs;
}

async function fetchDiscoverFeed(limit?: number) {
  const query = top40Query(
    limit ?? DEFAULT_LIMIT,
    playlistCursorMap[DISCOVER_FEED_ID],
  );
  console.log("fetchDiscoverFeed called", query);
  //TODO: Error handle
  const response = await executeQuery(query);
  const { songs, next } = response?.data?.trendingFeed ?? {};
  console.log("fetchDiscoverFeed result", response, songs, next);
  playlistCursorMap[DISCOVER_FEED_ID] = processCursor(next);
  return songs;
}

async function fetchPlaylist(playlistID: string, limit?: number) {
  const query = playlistQuery(
    playlistID,
    limit ?? DEFAULT_LIMIT,
    playlistCursorMap[playlistID],
  );
  console.log("fetchPlaylist called");
  //TODO: Error handle
  const response = await executeQuery(query);
  const { songs, next } = response?.data?.playlist?.songs ?? {};
  console.log("fetchPlaylist result", response, songs, next);
  playlistCursorMap[playlistID] = processCursor(next);
  return songs;
}

async function fetchArtistSongs(userID: string, songsLimit?: number) {
  const query = userSongsQuery(
    userID,
    songsLimit ?? DEFAULT_LIMIT,
    playlistCursorMap[userID],
  );
  console.log("fetchUserSongs called", userID);
  //TODO: Error handle
  const response = await executeQuery(query);
  const { songs, next } = response?.data?.user?.songs ?? {};
  console.log("fetchUserSongs result", response?.data?.user, next, songs);
  playlistCursorMap[userID] = processCursor(next);
  return songs;
}

async function fetchSCUserVoteSongs(userID: string, songsLimit?: number) {
  const query = userVotesQuery(
    userID,
    songsLimit ?? DEFAULT_LIMIT,
    playlistCursorMap[userID],
  );
  console.log("fetchSCUserVoteSongs called", query);
  //TODO: Error handle
  const response = await executeQuery(query);
  const { votes, next } = response?.data?.user?.votes ?? {};
  const nextCursor = processCursor(next);
  console.log(
    "fetchSCUserVoteSongs response",
    userID === getMyUser()?.id,
    userID,
    response,
    votes,
    next,
    nextCursor,
  );
  playlistCursorMap[userID] = nextCursor;
  const songs = votes.map((vote: Vote) => vote.song);
  return songs;
}

async function fetchCommunityFavorites(limit: number) {
  const playistID = "communityFavorites";
  const query = communityFavoritesQuery(
    getFriendIDList(),
    limit,
    playlistCursorMap[playistID],
  );
  const response = await executeQuery(query);
  //console.re.log("fetchCommunityFavorites", query, response);
  const { songs, next } = response?.data?.[playistID];
  playlistCursorMap[playistID] = processCursor(next);
  // const songs = favorites.map((vote: Vote) => vote.song);
  return songs;
}

async function fetchMutualDiscoveries(limit: number) {
  const playistID = "mutualDiscoveries";
  const query = mutualDiscoveriesQuery(
    getFriendIDList(),
    limit,
    playlistCursorMap[playistID],
  );
  const response = await executeQuery(query);
  console.log("fetchMutualDiscoveries", query, response);
  const { songs, next } = response?.data?.[playistID];
  playlistCursorMap[playistID] = processCursor(next);
  // const songs = discoveries.map((vote: Vote) => vote.song);
  return songs;
}

async function fetchSongByID(songID: string) {
  const response = await executeQuery(
    `{
    song (id: "${songID}") ${getFullSongQuery()}
  }`,
  );

  console.log("fetchSongByID", response);
  const { song } = response?.data;
  if (song) playlistCursorMap[songID] = "";
  return song as Song;
}

async function loadSocialSongs(
  type: string,
  limit: number | undefined,
  refresh?: boolean,
) {
  limit = limit ?? DEFAULT_LIMIT;
  let fetchSongs: Song[];
  const playlistData: PlaylistMetadata = {
    title: "",
    type: "Social",
  };
  switch (type) {
    case "mutualDiscoveries":
      fetchSongs = await fetchMutualDiscoveries(limit);
      playlistData.title = "Mutual Discoveries";
      playlistData.description = "Songs you and your friends love";
      break;
    case "communityFavorites":
      fetchSongs = await fetchCommunityFavorites(limit);
      playlistData.title = "Community Favorites";
      playlistData.description = "Your friends' favorite songs";
      break;
    default:
      return;
  }
  return loadFetchedSongs(fetchSongs, type, { refresh, playlistData });
}

async function loadUserSongs(
  userID: string,
  songsLimit?: number,
  refresh?: boolean,
) {
  const myUser = getMyUser();

  let fetchSongs: Song[];
  const thisUser = myUser.id === userID ? myUser : getUserMap()?.[userID];
  const userName = generateUserPlaylistTitle(thisUser);

  const playlistData: PlaylistMetadata = {
    title: userName,
    type: "Artist",
  };
  console.log(
    "loadUserSongs called",
    myUser.id === userID,
    thisUser,
    userID,
    songsLimit,
  );

  if (thisUser?.snapID || myUser.id === userID) {
    fetchSongs = await fetchSCUserVoteSongs(userID, songsLimit);
    if (thisUser?.id === myUser.id) {
      playlistData.title = "Upvoted Songs";
    }

    playlistData.type = "SCUser";
  } else {
    fetchSongs = await fetchArtistSongs(userID, songsLimit);
  }

  // const fetchSongs = await fetchUserSongs(userID, songsLimit);

  return loadFetchedSongs(fetchSongs, userID, { refresh, playlistData });
}

function generateUserPlaylistTitle(thisUser: User) {
  const userName =
    thisUser?.firstName || thisUser?.lastName
      ? `${thisUser?.firstName} ${thisUser?.lastName}`
      : thisUser?.username ?? "";
  return userName;
}

// async function loadTopSongs(limit?: number, refresh?: boolean) {
//   const fetchSongs = await fetchTopSongs(limit);
//   const playlistData: PlaylistMetadata = {
//     title: `Top Entries`,
//     type: "TopChart",
//     description: "Our greatest hits and latest winners",
//   };
//   return loadFetchedSongs(fetchSongs, "topSongs", { refresh, playlistData });
// }

async function loadChart(
  playlistID: string,
  limit?: number,
  refresh?: boolean,
) {
  console.log("loadChart called", playlistID, limit);
  const fetchSongs = await fetchChart(playlistID, limit);
  console.log("loadChart", playlistID, fetchSongs);

  const playlistData: PlaylistMetadata = getPlaylistData(playlistID);
  return loadFetchedSongs(fetchSongs, playlistID, { refresh, playlistData });
}

async function loadDiscoverFeed(limit?: number, refresh?: boolean) {
  // console.re.log("loadDiscoverFeed called", limit);
  const fetchSongs = await fetchDiscoverFeed(limit ?? DEFAULT_LIMIT);
  console.log("loadDiscoverFeed fetchSongs", fetchSongs);
  const playlistData: PlaylistMetadata = { title: "Discover", type: "Feed" };
  return loadFetchedSongs(fetchSongs, DISCOVER_FEED_ID, {
    refresh,
    playlistData,
  });
}

async function loadDiscoverFeedTest() {
  console.log("loadDiscoverFeed called");
  let fetchedSongs: string[] = [];
  for (let i = 0; i < 10; i++) {
    const start = Date.now();
    const fetchSongs = await fetchDiscoverFeed(DEFAULT_LIMIT);
    console.log(
      "loadDiscoverFeed fetchDiscoverFeed batch ",
      Date.now() - start,
    );
    const songIDs = fetchSongs.map((song: Song) => song.id);
    const promises: any[] = [];
    songIDs.forEach((songID: string) => {
      promises.push(executeQuery(voteMutation(songID, "NONE")));
    });
    const results = await Promise.all(promises);

    fetchedSongs = [...fetchedSongs, ...songIDs];
    console.log("loadDiscoverFeedTest VOTED_BATCH", results);
    console.log("loadDiscoverFeedTest SONGS", songIDs);
  }
  console.log("loadDiscoverFeedTest ALL SONGS", fetchedSongs);

  const duplicatesMap: any = {};
  const duplicatesCount: any = {};
  fetchedSongs.forEach((song) => {
    if (duplicatesMap[song]) {
      duplicatesCount[song] = (duplicatesCount[song] ?? 0) + 1;
    }
  });
  console.log("loadDiscoverFeedTest ALL DUPLICATES", fetchedSongs);
}
async function loadFeaturedPlaylist(
  playlistID: string,
  limit?: number,
  refresh?: boolean,
) {
  console.log("loadChart called", playlistID, limit);
  const fetchSongs = await fetchPlaylist(playlistID, limit);
  return loadFetchedSongs(fetchSongs, playlistID, { refresh });
}

async function loadSong(songID: string) {
  const song = await fetchSongByID(songID);
  const playlistData: PlaylistMetadata = {
    title: song?.title ?? "Untitled",
    type: "Song",
  };
  console.log("loadSong", song);
  return loadFetchedSongs([song], songID, { playlistData });
}

function loadFetchedSongs(
  fetchSongs: Song[] | undefined,
  playlistID: string,
  options: { refresh?: boolean; playlistData?: PlaylistMetadata },
) {
  const { songs, users } = extractUsersFromSongs(fetchSongs);
  if (
    options?.playlistData?.title === "" &&
    options?.playlistData?.type === "Artist" &&
    users?.[0]?.username
  ) {
    options.playlistData.title = generateUserPlaylistTitle(users[0]);
  }
  // if (!songs?.length) return false;
  pushSongs(songs ?? [], users ?? [], "top40", options);
  return { songs, users };
}

async function loadPlaylistHelper(
  id: string,
  limit?: number,
  refresh?: boolean,
) {
  if (refresh) clearCursor(id);
  if (!hasMoreSongs(id)) return;
  if (id === DISCOVER_FEED_ID) return loadDiscoverFeed(limit, refresh);
  if (id.slice(0, 4) === "isng") return loadSong(id);
  if (id.slice(0, 4) === "iusr") return loadUserSongs(id, limit, refresh);
  if (["mutualDiscoveries", "communityFavorites"].includes(id))
    return loadSocialSongs(id, limit, refresh);
  if (getPlaylistSet("featuredPlaylists")?.includes(id))
    return loadFeaturedPlaylist(id, limit, refresh);
  return loadChart(id, limit, refresh);
}

export async function loadPlaylist(
  id: string,
  limit?: number,
  refresh?: boolean,
) {
  if (playlistFetchingMap[id]) return;
  console.log("loadPlaylist", id);
  playlistFetchingMap[id] = true;
  const result = await loadPlaylistHelper(id, limit, refresh).catch(() => {
    playlistFetchingMap[id] = false;
  });
  playlistFetchingMap[id] = false;
  return result;
}

export async function loadPlaylists(playlistIDs: string[], limit?: number) {
  console.log("loadPlaylists", playlistIDs, limit);
  const currentPlaylists = getPlaylists();
  playlistIDs?.forEach((playlistID) => {
    if ((currentPlaylists?.[playlistID]?.length ?? 0) >= DEFAULT_LIMIT) return;
    loadPlaylist(playlistID);
  });
}

//Load playlist data one by one in order
async function loadPlaylistsChunksHelper(
  playlistIDs: string[],
  limit?: number,
  refresh?: boolean,
  isConcurrent?: boolean,
) {
  console.log(
    "loadPlaylistsChunksHelper",
    playlistIDs,
    limit,
    refresh,
    isConcurrent,
  );
  const currentPlaylists = getPlaylists();
  const promises: Promise<any>[] = [];
  for (let i = 0; i < playlistIDs.length; i++) {
    const playlistID = playlistIDs[i];
    if (
      !refresh &&
      (currentPlaylists?.[playlistID]?.length ?? 0) >= DEFAULT_LIMIT
    )
      continue;
    const promise = loadPlaylist(playlistID, limit, refresh);
    if (isConcurrent && promise) promises.push(promise);
    else await promise;
  }
  console.log("loadPlaylistsChunksHelper promises", promises);
  if (isConcurrent) await Promise.all(promises);
}

function filterRequestedPlaylistIDs(
  requestedPlaylistIDs: string[],
  rowsLimit?: number,
  omitFromMap?: boolean,
) {
  const playlistIDs: string[] = [];
  let count = 0;
  requestedPlaylistIDs.forEach((id) => {
    if (rowsLimit && count >= rowsLimit) return;
    if (!targetedPlaylistMap[id]) {
      count += 1;
      playlistIDs.push(id);
      if (!omitFromMap) targetedPlaylistMap[id] = true;
    }
  });
  console.log("filterRequestedPlaylistIDs", playlistIDs, requestedPlaylistIDs);
  return playlistIDs;
}

const CHUNK_SIZE = 5;
export async function loadPlaylistsChunks(
  requestedPlaylistIDs: string[],
  limit?: number,
  refresh?: boolean,
  concurrencyOptions?: {
    isConcurrent?: boolean;
    isConcurrentAfterFirstChunk?: boolean;
  },
) {
  const playlistIDs = requestedPlaylistIDs;
  const { isConcurrent, isConcurrentAfterFirstChunk } =
    concurrencyOptions ?? {};
  // const playlistIDs = filterRequestedPlaylistIDs(
  //   requestedPlaylistIDs,
  //   undefined,
  //   true,
  // );
  limit = limit ?? DEFAULT_LIMIT;
  const numFullChunks: number = isConcurrentAfterFirstChunk
    ? 1
    : Math.floor(limit / CHUNK_SIZE);
  const finalChunk: number = limit - CHUNK_SIZE * numFullChunks;
  const batchArray: number[] = Array(numFullChunks).fill(CHUNK_SIZE);
  if (finalChunk) batchArray.push(finalChunk);
  console.log(
    "loadPlaylistsChunks",
    limit,
    numFullChunks,
    finalChunk,
    batchArray,
  );
  for (let i = 0; i < batchArray.length; i++) {
    const batchSize = batchArray[i];
    //refresh on first batch only
    await loadPlaylistsChunksHelper(
      playlistIDs,
      batchSize,
      !i ? refresh : undefined,
      isConcurrent ?? (i ? isConcurrentAfterFirstChunk : undefined),
    );
  }
}

export async function loadPlaylistsConcurrent(
  playlistIDs: string[],
  limit?: number,
) {
  await loadPlaylistsChunksHelper(playlistIDs, limit, undefined, true);
}

const DEFAULT_ROWS_LIMIT = 10;

export function getTargetPlaylists(playlistIDList: string[]) {
  const targetPlaylists = filterRequestedPlaylistIDs(
    playlistIDList,
    undefined,
    true,
  );
  console.log("getTargetPlaylists", targetPlaylists);
  return targetPlaylists;
}

const targetedPlaylistMap: { [playlistID: string]: boolean } = {};

export function hasMoreTargetPlaylists(playlistIDList: string[]) {
  return getTargetPlaylists(playlistIDList).length > 0;
}

export async function paginatePlaylistRows(
  playlistIDList: string[],
  rowsLimit?: number,
) {
  rowsLimit = rowsLimit ?? DEFAULT_ROWS_LIMIT;
  const targetPlaylists = filterRequestedPlaylistIDs(playlistIDList, rowsLimit);
  console.log("targetPlaylists", targetPlaylists, playlistIDList);
  if (targetPlaylists?.length)
    await loadPlaylistsChunks(targetPlaylists, DEFAULT_LIMIT, false);
}

// setTimeout(loadDiscoverFeedTest, 1500);

type PlaylistSetTypes = "featuredPlaylists" | "featuredTags";
type PlaylistType = {
  id: string;
  name: string;
  desc: string;
  coverImages: ImageType[];
};
type ImageType = { url: string; size: number; format: string };

const cursorMapPlaylistSets: { [setID in PlaylistSetTypes]?: string } = {};

export function hasMorePlaylists(setID: PlaylistSetTypes) {
  const hasMore = !(cursorMapPlaylistSets?.[setID] === "");
  return hasMore;
}

export async function fetchFeaturedTags(limit?: number) {
  const setID: PlaylistSetTypes = "featuredTags";
  if (!hasMorePlaylists(setID)) return;
  const query = featuredTagsQuery(limit ?? 99, cursorMapPlaylistSets[setID]);
  const response = await executeQuery(query);
  const { tags, next } = response.data.featuredTags;

  cursorMapPlaylistSets[setID] = processCursor(next);
  setPlaylistsMetadata({}, setID, tags);
  return tags?.length ? tags : tagPlaylists;
}

export async function fetchFeaturedPlaylistsMetadata(limit?: number) {
  const setID: PlaylistSetTypes = "featuredPlaylists";
  if (!hasMorePlaylists(setID)) return;
  const query = featuredPlaylistsQuery(
    limit ?? 99,
    cursorMapPlaylistSets[setID],
  );
  console.log("fetchFeaturedPlaylistsMetadata called");
  const response = await executeQuery(query);
  console.log("fetchFeaturedPlaylistsMetadata", response);
  const { playlists, next } = response.data.featuredPlaylists as {
    playlists: PlaylistType[];
    next: string;
  };
  cursorMapPlaylistSets[setID] = processCursor(next);

  const playlistsMetaData: PlaylistsMetaDataMap = {};
  const playistIDs: string[] = [];
  playlists.forEach((playlist) => {
    const { id, name, desc, coverImages } = playlist;
    playistIDs.push(id);
    playlistsMetaData[id] = {
      title: name,
      description: desc,
      type: "Curated",
      url: coverImages?.[0]?.url,
    };
  });

  setPlaylistsMetadata(playlistsMetaData, setID, playistIDs);
}

// playlists: {
//       id: string
//       name: string
//       desc
//       coverImages {
//         url
//         size
//         format
//       }
//     }
