import { ActionCreator } from "redux";

const pushSongAction: ActionCreator<ContentActionTypes> = (
  songList: Song[],
  userList: User[],
  playlistID: string,
  options?: any,
) => {
  return {
    type: "PUSH_SONGS",
    playlistID,
    songList,
    userList,
    options,
  };
};

const setSongsAction: ActionCreator<ContentActionTypes> = (
  songList: Song[],
  options: any,
) => {
  return {
    type: "SET_SONGS",
    songList,
    options,
  };
};

const setUsersAction: ActionCreator<ContentActionTypes> = (
  users: User[],
  options: any,
) => {
  return {
    type: "SET_USERS",
    users,
    options,
  };
};

const setPlaylistsMetadataAction: ActionCreator<SetPlaylistsMetadataAction> = (
  playlistsMetadata: PlaylistsMetaDataMap,
  setID?: string,
  playlistIDs?: string[],
) => {
  return {
    type: "SET_PLAYLISTS_METADATA",
    setID,
    playlistIDs,
    playlistsMetadata,
  };
};

const setPlaylistAction: ActionCreator<SetPlaylistAction> = (
  playlistID: string,
  songIDList: string[],

  options?: any,
) => {
  return {
    type: "SET_PLAYLIST",
    playlistID,
    songIDList,
    options,
  };
};

const setCurrPlaylistAction: ActionCreator<ContentActionTypes> = (
  playlistID: string,
) => {
  return {
    type: "SET_CURR_PLAYLIST",
    playlistID,
  };
};

const setCurrIndexAndPlaylistAction: ActionCreator<
  ContentActionTypes
> = (update: { playlistID?: string; index?: number }) => {
  const { playlistID, index } = update;
  return {
    type: "SET_CURR_INDEX_AND_PLAYLIST",
    playlistID,
    index,
  };
};

const setCurrIndexAction: ActionCreator<ContentActionTypes> = (
  index: number,
) => {
  return {
    type: "SET_CURR_INDEX",
    index,
  };
};

const setIsBurstModeAction: ActionCreator<ContentActionTypes> = (
  isBurstMode: boolean,
) => {
  return {
    type: "SET_BURST_MODE",
    isBurstMode,
  };
};

const setFriendIDListAction: ActionCreator<ContentActionTypes> = (
  friendIDList: string[],
) => {
  return {
    type: "SET_FRIEND_ID_LIST",
    friendIDList,
  };
};

export {
  pushSongAction,
  setSongsAction,
  setUsersAction,
  setPlaylistAction,
  setCurrPlaylistAction,
  setCurrIndexAction,
  setIsBurstModeAction,
  setFriendIDListAction,
  setPlaylistsMetadataAction,
  setCurrIndexAndPlaylistAction,
};
