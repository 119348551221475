import BlurredOpacityBackground from "../../../../components/basicComponents/BlurredOpacityBackground";
import BifurcatedListItem, {
  LEFT_LIST_ITEM_IMAGE_SIZE,
  MARGIN,
} from "../../../../components/listitem/BifurcatedListItem";
import ProfileImage from "../../../../components/profileImage/ProfileImage";
import SocialMediaRow from "../../../../components/socialMediaRow/SocialMediaRow";
import { scale, standardMargin } from "../../../../utilities/scaling";
import { topInset } from "../../../../utilities/tabbarSize";
import {
  generateLocationString,
  generateSafeName,
  openURLInNewTab,
} from "../../../../utilities/utilities";
import { HEADER_HEIGHT } from "../../../../songPlayer/components/songPlayerPage/components/songPlayerPageOverlay/SongPlayerPageOverlay";
import BurstSwitch from "../../../../songPlayer/components/songPlayerPage/components/songPlayerPageOverlay/components/songPageOverlayFooter/components/burstSwitch/BurstSwitch";
import { black, opacity } from "../../../../utilities/colors";
import appleAppStoreLogoPng from "../../../../assets/pngs/appleAppStoreLogo.png";
import ClickableOpacityDiv from "../../../../components/clickableOpacityDiv/ClickableOpacityDiv";

type Props = {
  song: Song | undefined;
  isVerticle?: boolean;
};

const SOCIAL_MEDIA_BUTTON_SIZE = 40;
const SOCIAL_MEDIA_BUTTON_LEFT_MARGIN =
  MARGIN + LEFT_LIST_ITEM_IMAGE_SIZE / 2 - SOCIAL_MEDIA_BUTTON_SIZE / 2;

export default function Header({ song, isVerticle }: Props) {
  const user: User | undefined = song?.artist;
  const {
    id: userId,
    firstName,
    lastName,
    username,
    profileImage,
    location,
    spotifyURL,
    appleMusicURL,
    soundcloudURL,
    tiktokURL,
    instagramURL,
    twitterURL,
  } = user ?? {};
  const { baseImage, id, artist } = song ?? {};
  const { city, state } = location ?? {};
  const name = generateSafeName(firstName, lastName, username);
  const locationText = generateLocationString(city, state);
  const text1 = name ? [name] : undefined;
  const text2 = locationText ? [locationText] : undefined;

  const rightElement = isVerticle ? (
    <ClickableOpacityDiv
      style={{
        height: LEFT_LIST_ITEM_IMAGE_SIZE,
        display: "flex",
        alignItems: "center",
      }}
      withOpacity
      onClick={() => {
        openURLInNewTab("https://apps.apple.com/us/app/fwaygo/id1563957143");
        // sc.presentWebpage(url);
      }}
    >
      <img
        src={appleAppStoreLogoPng}
        style={{ height: LEFT_LIST_ITEM_IMAGE_SIZE * 0.6 }}
      />
    </ClickableOpacityDiv>
  ) : undefined;

  return (
    <BifurcatedListItem
      style={{
        paddingBottom: standardMargin,
      }}
      onClick={() => {}}
      noOpacity
      leftElement={
        <ProfileImage image={profileImage} size={LEFT_LIST_ITEM_IMAGE_SIZE} />
      }
      topLeft={text1}
      bottomLeft={text2}
      bottomElement={
        <SocialMediaRow
          size={SOCIAL_MEDIA_BUTTON_SIZE}
          spacing={SOCIAL_MEDIA_BUTTON_SIZE / 2}
          spotifyURL={spotifyURL}
          appleMusicURL={appleMusicURL}
          soundcloudURL={soundcloudURL}
          tiktokURL={tiktokURL}
          instagramURL={instagramURL}
          twitterURL={twitterURL}
          songId={id}
          userId={artist?.id || ""}
          songImage={baseImage}
          name={name}
        />
      }
      bottomElementWrapperStyle={{
        width: "100%",
        display: "flex-block",
        flexDirection: "row",
        left: SOCIAL_MEDIA_BUTTON_LEFT_MARGIN,
      }}
      rightElement={rightElement}
    />
  );
}
