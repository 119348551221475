import { httpRegex } from "./regexes";
import { showToast } from "./toast";

const parseNumberHelper = (number: number) => {
  const allowPlace = number < 100;
  if (allowPlace) number *= 10;
  number = Math.round(number);
  if (allowPlace) number /= 10;
  return number;
};

const parseLargeNumberStat = (number: number, isRanked?: boolean) => {
  const adjustedNumber = Math.round(number);
  const B = 1000000000;
  const M = 1000000;
  const K = 1000;
  if (adjustedNumber >= B) return `${parseNumberHelper(adjustedNumber / B)}B`;
  if (adjustedNumber >= M) return `${parseNumberHelper(adjustedNumber / M)}M`;
  if (adjustedNumber >= 10 * K)
    return `${parseNumberHelper(adjustedNumber / K)}K`;

  if (isRanked) {
    const j = adjustedNumber % 10,
      k = adjustedNumber % 100;
    if (j === 1 && k !== 11) {
      return adjustedNumber + "st";
    }
    if (j === 2 && k !== 12) {
      return adjustedNumber + "nd";
    }
    if (j === 3 && k !== 13) {
      return adjustedNumber + "rd";
    }
    return adjustedNumber + "th";
  }
  return `${adjustedNumber}`;
};

const generateSafeName = (
  firstName?: string,
  lastName?: string,
  username?: string,
) => {
  if (!username) return "";
  let name = "";
  if (firstName) {
    name = firstName;
    if (lastName) name += ` ${lastName}`;
    return name;
  } else return username;
};

const generateLocationString = (city?: string, state?: string) => {
  let location = "";
  if (city) {
    location += city;
    if (state) location += ",";
  }
  if (city) location += ` ${state}`;
  return location;
};

const generateLinearGradientBackground = (colors: string[], angle: number) => {
  if (colors.length < 2) return colors[0];
  let linearGradient = `linear-gradient(${angle}deg`;
  colors.forEach(
    (color: string, index: number) =>
      (linearGradient += `, ${color} ${(index / (colors.length - 1)) * 100}%`),
  );
  return `${linearGradient})`;
};

const createTimestampString = (seconds: number) => {
  let remSeconds = Math.floor(seconds);
  const hours = Math.floor(remSeconds / 3600);
  remSeconds = remSeconds % 3600;
  const minutes = Math.floor(remSeconds / 60);
  remSeconds = remSeconds % 60;
  return `${hours || ""}${hours ? ":" : ""}${minutes}:${
    remSeconds < 10 ? "0" : ""
  }${remSeconds}`;
};

const copyText = (url: string, name: string) => {
  //LOGIC TO COPY URL
  const urlToCopy = `${url?.match(httpRegex) ? "" : "https://"}${url}`;
  const el = document.createElement("textarea");
  el.value = urlToCopy;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  showToast(`Copied ${name} link`, "");
  // navigator.clipboard.writeText(el.value);

  // window.open(`${url?.match(httpRegex) ? "" : "https://"}${url}`);
};

function openURLInNewTab(url: string) {
  window.open(url, "_blank");
}

function interleaveArrays(array1: any[], array2: any[]) {
  let newArray: any[] = [];
  array1.forEach((item) => {
    newArray.push(item);
    const item2 = array2.shift();
    if (item2) newArray.push(item2);
  });
  if (array2.length) newArray = newArray.concat(array2);
  return newArray;
}

function capitalizeString(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

function getRandomItemFromArray(array: any[]) {
  return array[Math.floor(Math.random() * array?.length)];
}

const cacheImages = async (sources: string[]) => {
  const promises = await sources.map((src: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = (e) => resolve(e);
      img.onerror = (e) => reject(e);
    });
  });
  await Promise.all(promises);
};

function convertUrlToMime(url: string): string {
  const types: { [key: string]: string } = {
    ".avif": "image/avif",
    ".jpg": "image/jpeg",
    ".jpeg": "image/jpeg",
    ".jfif": "image/jpeg",
    ".pjpeg": "image/jpeg",
    ".pjp": "image/jpeg",
    ".png": "image/png",
    ".svg": "image/svg+xml",
    ".webp": "image/webp",
    ".bmp": "image/bmp",
    ".cur": "image/x-icon",
    ".tif": "image/tiff",
    ".tiff": "image/tiff",
  };
  url = url.trim();
  const extensionIndex = url.lastIndexOf(".");
  const extension = url.substring(extensionIndex, url.length);
  var mime = types[extension];
  return mime ?? "";
}

export {
  convertUrlToMime,
  parseLargeNumberStat,
  generateSafeName,
  generateLocationString,
  generateLinearGradientBackground,
  createTimestampString,
  copyText,
  interleaveArrays,
  capitalizeString,
  getRandomItemFromArray,
  cacheImages,
  openURLInNewTab,
};
