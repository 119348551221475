import { updateUserAction } from "../actions/user.actions";
import { store } from "../useRedux";
import { setUsers } from "./contentInterface";

export function updateMyUser(user: User) {
  store.dispatch(updateUserAction(user));
  setUsers([user]);
}

export function getMyUser() {
  return store.getState().user;
}
