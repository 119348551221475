import { useState } from "react";
import { gradientPinkYellow, red, white } from "../../utilities/colors";
import { generateLinearGradientBackground } from "../../utilities/utilities";
import profleImagePng from "../../assets/pngs/Profile.png";

type Props = {
  image?: string;
  size: number;
};

type ImageErrorData = {
  isLoaded: boolean;
  isError: boolean;
};

export default function ProfileImage({ image, size }: Props) {
  const [imageErrorData, setImageErrorData] = useState<ImageErrorData>({
    isLoaded: false,
    isError: false,
  });
  const { isLoaded, isError } = imageErrorData;
  const useDefault = isError || !image;
  return (
    <div
      style={{
        position: "relative",
        height: size,
        width: size,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        visibility: isLoaded ? "visible" : "hidden",
        overflow: "hidden",
        flexShrink: 0,
        border: useDefault ? `1px white solid` : undefined,
        boxSizing: "border-box",
        borderRadius: "50%",
        opacity: useDefault ? 0.7 : 1,
      }}
    >
      <img
        onLoad={() =>
          setImageErrorData((d) => {
            return { ...d, ...{ isLoaded: true } };
          })
        }
        onError={() =>
          setImageErrorData((d) => {
            return { ...d, ...{ isError: true } };
          })
        }
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
        src={useDefault ? profleImagePng : image}
        //alt={"profileImage"}
      />
    </div>
  );
}
