import BurstSwitch from "../../../../songPlayer/components/songPlayerPage/components/songPlayerPageOverlay/components/songPageOverlayFooter/components/burstSwitch/BurstSwitch";
import { black, opacity } from "../../../../utilities/colors";
import SongPageControls from "./SongPageControls";
import SongPageSlider from "./songPageSlider/SongPageSlider";
import SongPageTags from "./SongPageTags";

type Props = {
  withBurst?: boolean;
  song: Song;
};

export default function Footer({ song, withBurst }: Props) {
  return (
    <div
      style={{
        position: "relative",
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // background: opacity(black, 0.2),
        // paddingTop: 20,
      }}
    >
      <SongPageTags song={song} />
      <SongPageSlider song={song} withBurst={withBurst} />
      <SongPageControls />
    </div>
  );
}
