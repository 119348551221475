import sc from "@snapchat/snapcanvas-sdk";
import { CLOUDFRONT_S3 } from "../constants";
import { getRandomItemFromArray } from "./utilities";

function isPermissionGranted(scopes: any) {
  if (
    scopes.permissions[sc.PermissionScope.ACTIVITY_VISIBILITY]
      .permissionValue === sc.PermissionValue.ALLOW
  ) {
    console.log("Permissions are granted");
    return true;
  } else {
    console.log("Permissions are granted");
    return false;
  }
}

async function requestPermission() {
  const result = await sc.requestPermission(
    sc.PermissionScope.ACTIVITY_VISIBILITY,
  );
  return isPermissionGranted(result);
}

async function checkCurrentPermission() {
  const response = await sc.getPermissions([
    sc.PermissionScope.ACTIVITY_VISIBILITY,
  ]);
  return isPermissionGranted(response);
}

export async function getDiscoverableFriends() {
  const permissionGranted = await checkCurrentPermission();
  if (!permissionGranted) requestPermission();
  try {
    const result = await sc.getDiscoverableFriends();
    return result;
  } catch (e) {
    // console.re.error(e, "discoverable friends error");
  }
}

export async function friendSession(friendId: string) {
  const response = await sc.switchToFriend(friendId);
  return response;
}

const bitmojiVariants = [
  sc.AvatarVariants.DEFAULT,
  // sc.AvatarVariants.HAPPY,
  // sc.AvatarVariants.BORED,
  // sc.AvatarVariants.LAUGHINGCRY,
  // sc.AvatarVariants.CRY,
  // sc.AvatarVariants.SHOCK,
  // sc.AvatarVariants.SAD,
  // sc.AvatarVariants.THINKING,
  // sc.AvatarVariants.RELAX,
  // sc.AvatarVariants.CONFUSED,
  // sc.AvatarVariants.DEER,
  // sc.AvatarVariants.CAT,
  // sc.AvatarVariants.DOG,
  // sc.AvatarVariants.HALO,
  // sc.AvatarVariants.RAINBOW,
  // sc.AvatarVariants.MONEYEYES,
  // sc.AvatarVariants.WAVE,
  // sc.AvatarVariants.KISS,
  // sc.AvatarVariants.HEARTEYES,
  // sc.AvatarVariants.SUNGLASSES,
  // sc.AvatarVariants.EVIL,
  // sc.AvatarVariants.PEEK,
  // sc.AvatarVariants.SLEEP,
  // sc.AvatarVariants.SHH,
  // sc.AvatarVariants.HEARTBREAK,
  // sc.AvatarVariants.STAYTUNED,
  // sc.AvatarVariants.SHRUG,
  // sc.AvatarVariants.EMBARRASSED,
  // sc.AvatarVariants.YAWN,
  // sc.AvatarVariants.CHEEKY,
  // sc.AvatarVariants.GRIN,
  // sc.AvatarVariants.ANGRY,
  // sc.AvatarVariants.CROWN,
  // sc.AvatarVariants.SURPRISE,
  // sc.AvatarVariants.WINK,
  // sc.AvatarVariants.ROLL,
  // sc.AvatarVariants.SWEAT,
];

export async function getBitmoji(snapID: string | undefined) {
  if (!snapID) return;
  const avatar2DResponse = await sc.fetchAvatar2D(
    snapID,
    getRandomItemFromArray(bitmojiVariants),
    512,
  );
  return avatar2DResponse.url;
}

const musicStickerIDs = [
  "20013900",
  "10221301",
  "10231995",
  "10220695",
  "10220695",
  "10220683",
  "20041462",
  "20083342",
  "20042967",
  "20037257",
];

export function getMusicBitmojiURL() {
  const stickerId = getRandomItemFromArray(musicStickerIDs);
  const avatarId = sc?.app?.user?.avatarId;

  return getBitmojiSticker(avatarId ? stickerId : "20013900");
}

export function getLeaderboardBitmojiURL() {
  const stickerId = "20063984";

  return getBitmojiSticker(stickerId);
}

export function getBitmojiSticker(stickerId: string) {
  const bitmojiOsBaseUrl = "https://sdk.bitmoji.com/me/sticker";
  const avatarId = sc?.app?.user?.avatarId;
  let url = "";
  switch (!!avatarId) {
    case true:
      url = bitmojiOsBaseUrl + "/" + sc?.app?.user?.avatarId + "/" + stickerId;
      break;
    case false:
      url = `${CLOUDFRONT_S3}/mini/bitmoji-stickers/0/${stickerId}.png`;
  }
  //console.re.log(url);
  return url;
}

export const thumbsUpIDs = [
  "20062035",
  "7681590",
  "20082591",
  "10211715",
  "10120160",
  "20022694",
  "20085133",
];

export const thumbsDownIDs = [
  "10230720",
  "10222419",
  "9101103",
  "20085059",
  "20085060",
  "10212754",
  "20082713",
  "10211713",
];

export function getVoteBitmojiStickerData() {
  const thumbsUpStickerId = getRandomItemFromArray(thumbsUpIDs);
  const thumbsDownStickerId = getRandomItemFromArray(thumbsDownIDs);
  return {
    upvoteSticker: getBitmojiSticker(thumbsUpStickerId),
    downvoteSticker: getBitmojiSticker(thumbsDownStickerId),
  } as StickerData;
}
