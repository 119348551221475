import lottie, { AnimationItem } from "lottie-web";
import { MutableRefObject, useEffect, useRef } from "react";
import burstJson from "./burst.json";
import "../lottie.scss";
import { black, opacity } from "../../../utilities/colors";

export type Props = {
  animationRef: MutableRefObject<AnimationItem | undefined>;
  height: number;
  style?: Style;
};

export default function Burst({ animationRef, height, style }: Props) {
  const lottieRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    animationRef.current = lottie.loadAnimation({
      autoplay: false,
      loop: false,
      container: lottieRef.current!,
      animationData: burstJson,
      renderer: "svg",
      rendererSettings: {
        className: "lottie",
      },
    });

    animationRef.current.setSpeed(8);
    return () => {
      animationRef.current?.destroy();
    };
  }, []);

  return (
    <div
      ref={lottieRef}
      style={{
        ...{
          height,
          borderRadius: height / 2,
          // backgroundColor: opacity(black, 0.4),
          width: height * (400 / 120),
        },
        ...style,
      }}
    />
  );
}
