import centeredGreyLogoPng from "../../assets/pngs/CenteredGreyLogo.png";
import centeredWhiteLogoPng from "../../assets/pngs/CenteredWhiteLogo.png";
import centeredBlackLogoPng from "../../assets/pngs/CenteredBlackLogo.png";

import { scale } from "../../utilities/scaling";
import "./loading.scss";
import { black } from "../../utilities/colors";

export enum SOLID_LOADING_COLOR {
  white,
  black,
}

export type Props = {
  size?: number;
  solidLoadingColor?: SOLID_LOADING_COLOR;
  pulse?: boolean;
};

const DEFAULT_SIZE = scale(40);
export default function Loading({ size, solidLoadingColor, pulse }: Props) {
  var src = centeredGreyLogoPng;
  switch (solidLoadingColor) {
    case SOLID_LOADING_COLOR.white:
      src = centeredWhiteLogoPng;
      break;
    case SOLID_LOADING_COLOR.black:
      src = centeredBlackLogoPng;
  }

  return (
    <div id={`loading`}>
      <img
        id={pulse ? "pulse" : undefined}
        src={src}
        style={{
          display: "block",
          height: size ?? DEFAULT_SIZE,
          width: size ?? DEFAULT_SIZE,
          objectFit: "contain",
        }}
      />
    </div>
  );
}
