import EventEmitter from "events";

const fwaygoEventEmitter = new EventEmitter();

export type TrackEventType =
  | "ended"
  | "loadstart"
  | "seeking"
  | "playing"
  | "pause"
  | "canplaythrough"
  | "volumechange"
  | "progress"
  | "loadStart"
  | "error";

export type FwaygoEventType =
  | TrackEventType
  | "SERVER_ISSUE"
  | "SEARCH_QUERY"
  | "USER_PLAY_PAUSE"
  | string;

export function emitEvent(eventType: FwaygoEventType, data?: any) {
  fwaygoEventEmitter?.emit(eventType, data);
}

export function createEventListener(
  eventType: FwaygoEventType,
  callback: (data: any) => void,
) {
  const listener = fwaygoEventEmitter?.addListener(eventType, callback);
  return listener;
}

export function removeEventListener(
  eventType: FwaygoEventType,
  callback: (data: any) => void,
) {
  const listener = fwaygoEventEmitter?.removeListener(eventType, callback);
  return listener;
}
