import { useEffect, useState } from "react";
import useActiveSong from "../songPlayer/hooks/useActiveSong";
import useFeedFetcherWP from "./hooks/useFeedFetcherWP";
import SongPage from "./songPage/SongPage";
import LoadingScreen from "../components/loadingScreen/LoadingScreen";
import { usePalette } from "react-palette";

export type Props = {};

export default function WebPlayer(props: Props) {
  const [backgroundColor, setBackgroundColor] = useState<string | null>(null);
  const { activeSong } = useActiveSong();

  const { data } = usePalette(activeSong?.baseImage ?? "");
  const color = data.vibrant;

  useEffect(() => {
    if (color) setBackgroundColor(color);
  }, [color]);

  useFeedFetcherWP();

  // if (!backgroundColor) return <LoadingScreen />;

  return (
    <SongPage song={activeSong} backgroundColor={backgroundColor ?? "black"} />
  );
}
