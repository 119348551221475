import { sBrowser } from "./utilities/browser";

export const REACT_APP_APP_ID = "d4091fe9-c94b-4671-b05a-b3ebf074d3e9";
export const REACT_APP_AD_SLOT_ID = "2e7cc999-262c-4aef-ba89-330204ed6fce";
export const REACT_APP_IS_DEVELOPMENT = true;
export const REACT_APP_FIREBASE_API_KEY =
  "AIzaSyCGZh7V4nu_wfRLKeUCF_6n5k8K5ru5YAw";
export const REACT_APP_FIREBASE_AUTH_DOMAIN = "fwaygo-309023.firebaseapp.com";
export const REACT_APP_FIREBASE_PROJECT_ID = "fwaygo-309023";
export const REACT_APP_FIREBASE_STORAGE_BUCKET = "fwaygo-309023.appspot.com";
export const REACT_APP_MESSAGING_SENDER_ID = "1077750168927";
export const REACT_APP_MEASUREMENT_ID = "G-T0NVBED0DF";

export const DISCOVER_FEED_ID = "top40";

export const STATSIG_ENVIRONMENT = "production";
export const DATADOG_ENVIRONMENT = "prod";
export const DATADOG_RUM_TOKEN = "pub322835b995db6231a28dd7d6206a7a62";
export const DATADOG_LOG_TOKEN = "pub7e7abc97ba727306311b06008bc88fb5";

export const NOTIFICATIONS = {
  VOTE: "19da6b85-c9b2-4dc2-b12c-761ccc2469cf",
};
export const NOTIFICATION_THRESHOLD = 5;

export const STATSIG_CLIENT_KEY =
  "client-6L6T4nctJVN82o0OLNqoca241ohs4sSnpxsdqvgaA8G";
export const MIXPANEL_TOKEN = "c3ecebb7acf018912e4dbcaab8119135";
export const MIXPANEL_DEBUG = false;

//REMOVE TRAILING SLASH
export const CLOUDFRONT = "https://d3ag8aoc8khnc1.cloudfront.net";
export const CLOUDFRONT_S3 = "https://dp6knr9126b6l.cloudfront.net";
export const DEEPLINK_DOMAIN = "backend.fwaygoapp.com";

export const GENERAL_ANIMATION_DURATION = 300;

declare type EnvType =
  | "prod"
  | "prod-old"
  | "dev1"
  | "dev2"
  | "dev3"
  | "test"
  | "staging";

//CHANGES TO THESE VARIABLES SHOULDNT BE COMMITED
export const env: EnvType = "prod-old";
export const version: string = "v1.1.1";

const hardCodeIsDev = null;

export const isAndroid = sBrowser === "Google Chrome or Chromium";
export const isAutoplayDisabled = isAndroid;

export const disableTutorial = true;
export const enableTutorial = false;
export const hardcodeIsFirstTimeUser = false;

export const useTestUserAsFriend = false;

export const isShowingAds = true;
export const MAX_ADS_PER_MIN = 1;
export const MIN_TO_AD_START = 1;

export const isDev = hardCodeIsDev ?? !env.includes("prod");

export const leaderboardID = "d26655c0-f2f0-423a-b25f-644439e7420a";

let endpoint = "";
let ws_endpoint = "";

switch (env as EnvType) {
  case "prod":
    endpoint = "https://api.fwaygoapp.com";
    ws_endpoint = "wss://api.fwaygoapp.com";
    break;
  case "prod-old":
    endpoint = "https://backend.fwaygoapp.com";
    ws_endpoint = "wss://backend.fwaygoapp.com";
    break;
  case "test":
    endpoint = "https://test.fwaygoapp.com";
    ws_endpoint = "wss://test.fwaygoapp.com";
    break;
  case "staging":
    endpoint = "https://staging.fwaygoapp.com";
    ws_endpoint = "wss://staging.fwaygoapp.com";
    break;
  case "dev1":
    endpoint = "http://54.241.245.132:4000";
    ws_endpoint = "ws://54.241.245.132:4000";
    break;
  case "dev2":
    endpoint = "http://54.177.165.27:4000";
    ws_endpoint = "ws://54.177.165.27:4000";
    break;
  case "dev3":
    endpoint = "http://54.219.104.244";
    ws_endpoint = "ws://54.219.104.244";
    break;
}

// const promo = "https://d3ag8aoc8khnc1.cloudfront.net/assets/songs/promo.mp4";
//export const ENDPOINT = 'https://54.241.245.132'
export const ENDPOINT = endpoint;
export const WS_ENDPOINT = ws_endpoint;
export const DEFAULT_COLOR = "aa2290";
export const NULL_PROFILE =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJg0an79elDXmfpaf-OEvDMxbSTiaHYrwrFQ&usqp=CAU";

export const fullUserKeys = {
  id: undefined,
  // snapID: undefined,
  username: undefined,
  profileImage: undefined,
  coverImage: undefined,
  firstName: undefined,
  lastName: undefined,
  location: undefined,
  bio: undefined,

  spotifyURL: undefined,
  appleMusicURL: undefined,
  instagramURL: undefined,
  soundcloudURL: undefined,
  tiktokURL: undefined,
  twitterURL: undefined,

  badge: undefined,

  // stickerImage: undefined,
};

export const fullArtistKeys = {
  id: undefined,
  username: undefined,
  profileImage: undefined,
  badge: undefined,
};

export const fullSongKeys = {
  id: undefined,
  fullAudio: undefined,
  burstAudio: undefined,
  baseImage: undefined,
  // stickerImage: undefined,
  title: undefined,
  tags: undefined,
  duration: undefined,
  burstStart: undefined,
  burstEnd: undefined,
  // myVote: undefined,
  // votesNum: undefined,
  // isLiked: undefined,
  // likesNum: undefined,
  // top8Num: undefined,
  // published: undefined,
  // streamsNum: undefined,
  // shareableVideo: undefined,
  exists: undefined,
  artist: undefined,

  // isExplicit: undefined,
};

export const fullSongKeysRecc = {
  ...fullSongKeys,
  version: undefined,
};

let fullUserQueryBuilder = "{ ";
Object.keys(fullUserKeys).forEach((key) => {
  if (key === "location")
    fullUserQueryBuilder += `location { city, state, country } `;
  else fullUserQueryBuilder += `${key} `;
});
fullUserQueryBuilder += "}";

const fullArtistQueryBuilder = `artist ${fullUserQueryBuilder}`;

export const myVoteQuery = `myVote {
      type
    } `;

const songQueryBuildRules = (key: string, target: string) => {
  switch (key) {
    case "myVote":
      target += myVoteQuery;
      break;
    case "artist":
      target += fullArtistQueryBuilder;
      break;
    default:
      target += `${key} `;
      break;
  }
  return target;
};

// var fullSongQueryBuilder = "{ ";
// Object.keys(fullSongKeys).forEach((key) => {
//   fullSongQueryBuilder = songQueryBuildRules(key, fullSongQueryBuilder);
// });
// fullSongQueryBuilder += "}";

let fullSongQueryBuilder = "{ ";
let fullSongFieldsBuilder = "";
Object.keys(fullSongKeys).forEach((key) => {
  fullSongFieldsBuilder = songQueryBuildRules(key, fullSongFieldsBuilder);
});
fullSongQueryBuilder += fullSongFieldsBuilder + "}";

export const fullSongFields = fullSongFieldsBuilder;

let fullSongQueryBuilderRecc = "{ ";
Object.keys(fullSongKeysRecc).forEach((key) => {
  fullSongQueryBuilderRecc = songQueryBuildRules(key, fullSongQueryBuilderRecc);
});
fullSongQueryBuilderRecc += "}";

const fullSongQuery = fullSongQueryBuilder;
export const fullUserQuery = fullUserQueryBuilder;
export const fullArtistQuery = fullArtistQueryBuilder;
export const fullSongQueryRecc = fullSongQueryBuilderRecc;
export const fullMyUserQuery = "{ id snapID  ads {frequency} score}";

export const emptyArray = [];
