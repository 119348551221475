import SongPlayerPageTagsWP from "./components/footer/SongPageTags";
import SongPageBackground from "./components/SongPageBackground";
import SongSquareImage from "./components/SongSquareImage";
import SongPageControlBar from "./components/songPageControlBar/SongPageControlBar";
import Burst from "../../assets/lotties/burst/Burst";
import BurstSwitch from "../../songPlayer/components/songPlayerPage/components/songPlayerPageOverlay/components/songPageOverlayFooter/components/burstSwitch/BurstSwitch";
import FwaygoAd from "./components/FwaygoAd";
import Header from "./components/header/Header";
import { useDims, scale, standardMargin } from "../../utilities/scaling";
import Footer from "./components/footer/Footer";
import { black, opacity } from "../../utilities/colors";

export type Props = {
  song: Song;
  backgroundColor?: string;
};

export default function SongPage(props: Props) {
  const { backgroundColor } = props;
  const { width, height } = useDims();
  const squareImageSize = Math.min(width - standardMargin * 2, height * 0.8);

  const ratioLimit = 1;
  const isVertical = height / width > ratioLimit;

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
      }}
    >
      {isVertical ? (
        <SongPageVertical
          {...props}
          squareImageSize={squareImageSize}
          height={height}
        />
      ) : (
        <SongPageHorizontal
          {...props}
          squareImageSize={squareImageSize}
          height={height}
        />
      )}
      <SongPageBackground
        image={props.song?.baseImage}
        backgroundColor={backgroundColor}
      />
    </div>
  );
}

type InnerPageProps = Props & {
  squareImageSize: number;
  height?: number;
};

function SongPageVertical({ song, squareImageSize, height }: InnerPageProps) {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        paddingBottom: standardMargin,
      }}
    >
      <Header song={song} isVerticle />
      <div
        style={{
          position: "relative",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            position: "relative",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <SongSquareImage image={song?.baseImage} size={height!} isVertical />
        </div>
        <div
          style={{
            position: "relative",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Footer song={song} />
        </div>
      </div>
    </div>
  );
}

function SongPageHorizontal({ song, squareImageSize, height }: InnerPageProps) {
  const centerAppStoreAd = height! > 500;
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row-reverse",
      }}
    >
      <div
        style={{
          position: "relative",
          height: "100%",
          // aspectRatio: `1 / 1`,
          maxWidth: height,
          // width: "100%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SongSquareImage image={song?.baseImage} size={squareImageSize} />
      </div>
      <div
        style={{
          position: "relative",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minWidth: "50%",
          boxSizing: "border-box",
          backgroundColor: opacity(black, 0.2),
          paddingBottom: standardMargin,
          overflow: "scroll",
        }}
      >
        <Header song={song} isVerticle={!centerAppStoreAd} />
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {centerAppStoreAd && <FwaygoAd />}

          <div
            style={{
              position: "relative",
              width: "90%",
            }}
          >
            <Footer song={song} withBurst />
          </div>
        </div>
      </div>
    </div>
  );
}
