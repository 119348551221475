import { DISCOVER_FEED_ID } from "../constants";
import {
  getContentData,
  getPlaylist,
  getPlaylistSet,
  getSongMap,
  setCurrIndexAndPlaylist,
  setIsBurstMode,
} from "../redux/interfaces/contentInterface";
import { getIntroState, IntroState } from "../screens/intro/IntroVideoPlayer";
import { emitEvent } from "../utilities/events";
import { topEntriesID } from "../utilities/mutableAppData";
import { logEvent } from "../utilities/statsig";
import { showToast } from "../utilities/toast";
import {
  loadAudio,
  playThisAudio,
  resetAudio,
  togglePlayState,
  seekAudio,
  playAudioUserAction,
  pauseAudioUserAction,
} from "./audioPlayer/audioPlayer";
import { loadFeed } from "./fetchFunctions/feedFetchFunctions";
import { loadPlaylist } from "./fetchFunctions/navigationFetchFunctions";
import { setSongSeen } from "./fetchFunctions/voteFunctions";
import { streamAddMutation } from "./query/mutations";
import { executeQuery } from "./query/queryUtils";

let currPlaylist: string = DISCOVER_FEED_ID;
let currAudioIndex = 0;
let currDiscoverAudioIndex = 0;
let isBurstMode = true;

var currSongListenStart: number | undefined;

export function getCurrPlaylistID() {
  return currPlaylist;
}

export function getCurrIndex() {
  return currAudioIndex;
}

export function getCurrDiscoverAudioIndex() {
  return currDiscoverAudioIndex;
}

export function selectIsBurstMode(newIsBurstMode: boolean) {
  isBurstMode = newIsBurstMode;
  resetAudio();
  selectAudioFromPlaylist(currPlaylist, currAudioIndex);
  setIsBurstMode(newIsBurstMode);
}

function setCurrentSongSeen() {
  const thisPlaylist = getPlaylist(currPlaylist);
  const thisSongID = thisPlaylist?.[currAudioIndex];
  if (!thisSongID) return;
  setSongSeen(thisSongID);
  if (currSongListenStart) {
    // const now = new Date();
    // const timestamp = now?.getTime()
    const timeDeltaMS = Date.now() - currSongListenStart;
    logEvent("SONG LISTEN TIME", (timeDeltaMS / 1000).toString(), {
      id: thisSongID,
      title: getSongMap()?.[thisSongID]?.title,
    });
    executeQuery(streamAddMutation(thisSongID, new Date(), timeDeltaMS)).then(
      (response) => {
        // console.log("streamAdd", response);
      },
    );
    currSongListenStart = undefined;
  }
}

export function toggleIsBurstMode() {
  const newIsBurstMode = !isBurstMode;
  selectIsBurstMode(newIsBurstMode);
  return newIsBurstMode;
}

function selectPlaylist(playlistID: string) {
  selectPlaylistAndAudio({ playlistID });
}

function selectAudio(index: number) {
  selectPlaylistAndAudio({ index });
}

function selectPlaylistAndAudio(update: {
  playlistID?: string;
  index?: number;
}) {
  const { playlistID, index } = update ?? {};

  if (index !== undefined && playlistID === DISCOVER_FEED_ID)
    currDiscoverAudioIndex = index;

  // if (
  //   index !== currAudioIndex ||
  //   (playlistID !== currPlaylist && (index || playlistID))
  // )

  if (playlistID !== undefined) currPlaylist = playlistID;
  if (index !== undefined) currAudioIndex = index;
  setCurrIndexAndPlaylist(update);
}

function getAudioURL(songID: string) {
  const thisSong = getSongMap()?.[songID];
  if (!thisSong) return;
  return isBurstMode ? thisSong.burstAudio : thisSong.fullAudio;
}

export function getNextSongURL() {
  const { playlists, currPlaylist, currIndex } = getContentData();
  const nextIndex = currIndex + 1;
  const thisPlaylist = playlists?.[currPlaylist] ?? [];
  if (nextIndex >= thisPlaylist.length) return;
  const songID = thisPlaylist?.[nextIndex];
  if (!songID) return;
  return getAudioURL(songID);
}

export async function loadAndPlaySong(songID: string) {
  await loadPlaylist(songID);
  console.log("OPEN SONG", getContentData());
  emitEvent("setTargetSongID", songID);
  selectAudioFromPlaylist("top40", 0);
  // play();
}

export function selectAudioFromPlaylist(playlistID: string, index: number) {
  console.log("selectDiscoverFeed selectAudioFromPlaylist", playlistID, index);

  setCurrentSongSeen();
  const thisPlaylist = getPlaylist(playlistID);
  if (!thisPlaylist) return;
  if (thisPlaylist.length <= index) {
    if (playlistID === DISCOVER_FEED_ID) return selectIndexCurrentPlaylist(0);
    return selectDiscoverFeed();
  }
  selectPlaylistAndAudio({ playlistID, index });
  const thisSongID = thisPlaylist?.[index];
  const song = getSongMap()[thisSongID];
  console.log("selectAudioFromPlaylist song", song);

  logEvent("STREAM", song?.id ?? "", {
    title: song?.title,
    artist: song?.artist?.username,
    id: song?.id,
  });
  const songURL = getAudioURL(thisSongID);
  console.log("selectAudioFromPlaylist songURL", songURL);
  if (!songURL) return;
  const playTarget = loadAudio(songURL);
  if (
    playTarget === songURL
    // getIntroState() === IntroState.SkippedTutorial
  ) {
    playThisAudio();
    currSongListenStart = Date.now();
  }
}

function selectCurrentAudio() {
  selectAudioFromPlaylist(currPlaylist, currAudioIndex);
}

export function selectIndexCurrentPlaylist(index: number) {
  console.log(
    "selectDiscoverFeed selectIndexCurrentPlaylist",
    currPlaylist,
    index,
  );
  selectAudioFromPlaylist(currPlaylist, index);
}

export function selectNextCurrentPlaylist() {
  selectAudioFromPlaylist(currPlaylist, currAudioIndex + 1);
}

export function selectPrevCurrentPlaylist() {
  selectAudioFromPlaylist(currPlaylist, currAudioIndex - 1);
}

export function selectDiscoverFeed(alternateID?: string) {
  const discoverID = alternateID ?? DISCOVER_FEED_ID;
  const discoverLength = getPlaylist(discoverID)?.length;
  function fallbackHandler() {
    if (!discoverLength) {
      const fallBackList = [
        topEntriesID,
        ...(getPlaylistSet("featuredTags") ?? []),
      ];
      for (let i = 0; i < fallBackList.length; i++) {
        const fallbackID = fallBackList[i];
        const fallbackPlaylist = getPlaylist(fallbackID);
        if (fallbackPlaylist?.length) {
          showToast("Entering Discover", "🔥");
          selectAudioFromPlaylist(fallbackID, 0);
          // console.log("found fallback", fallbackID, fallbackPlaylist);
          return true;
        }
      }
      // console.re.log("no fallback");
      return false;
    }
    selectAudioFromPlaylist(discoverID, 0);
    return true;
  }
  if (
    discoverID === currPlaylist &&
    currDiscoverAudioIndex === currAudioIndex &&
    discoverLength
  ) {
    // console.re.log(
    //   "already selected ",
    //   discoverID,
    //   currPlaylist,
    //   currAudioIndex,
    //   discoverLength,
    // );
    selectAudioFromPlaylist(discoverID, currDiscoverAudioIndex);
    return true;
  }
  if (!discoverLength) return fallbackHandler();

  showToast("Entering Discover", "🔥");
  console.log("selectDiscoverFeed", discoverID, currDiscoverAudioIndex);
  selectAudioFromPlaylist(discoverID, currDiscoverAudioIndex);
  return true;
}

export function play() {
  // if (getIntroState() !== IntroState.SkippedTutorial) return false;
  console.log("play");
  const success = playAudioUserAction();
}

export function pause() {
  pauseAudioUserAction();
}

export function seek(rangeValue: number) {
  seekAudio(rangeValue);
  const songId = getCurrentSongID();
  logEvent("SEEK", songId ?? "", { id: songId, rangeValue: rangeValue });
}

const BUFFER_SIZE = 10;
export function togglePlayPause() {
  return togglePlayState();
}

export async function assessQ() {
  const playlist = getPlaylist(currPlaylist) ?? [];
  if (playlist?.length - currAudioIndex < BUFFER_SIZE) {
    const status = await loadFeed(currPlaylist);
  }
  // setTimeout(() => {
  //   console.log("assesQ Redux", getContentData());
  // }, 1000);
  return;
}

export function getCurrentSongID() {
  const thisPlaylist = getPlaylist(currPlaylist);
  const thisSongID = thisPlaylist?.[currAudioIndex];
  return thisSongID;
}
