import { CursorOptions } from "prettier";
import {
  fullMyUserQuery,
  fullSongFields,
  fullUserQuery,
  myVoteQuery,
} from "../../constants";

const generateInput = (limit: number, cursor: string | undefined) =>
  `input: {${cursor ? `cursor: ${cursor},` : ""} limit: ${limit}}`;

export const songVoteDataFields = () => {
  //TODO add this back when social is implemented
  // friendVotes(friendIDs: ${JSON.stringify(getFriendIDList() ?? [])}) {
  //   voter {
  //     id
  //   }
  //   type
  // }
  return `
  id
  votesNum {
    upvotes
    downvotes
  }
  ranks {
    tag
    rank
  }
  ${myVoteQuery}
`;
};

export const getFullSongQuery = () => `
{
  ${fullSongFields}
}
`;

export const featuredPlaylistsQuery = (
  limit: number,
  cursor: string | undefined,
) => `
query {
  featuredPlaylists(${generateInput(limit, cursor)}) {
    playlists {
      id
      name
      desc
      coverImages {
        url
        size
        format
      }
    }
    next
  }
}`;

export const playlistQuery = (
  playlistID: string,
  limit: number,
  cursor?: string,
) => `
query {
  playlist(id: "${playlistID}") {
    id
    songs(${generateInput(limit, cursor)}) {
      songs ${getFullSongQuery()}
      next
    }
  }
}`;

export const snapchatFeedQuery = (limit: number, cursor?: string) => `
query {
  snapchatFeed(${generateInput(limit, cursor)}) {
    songs ${getFullSongQuery()}
    next
  }
}`;

export const top40Query = (limit: number, cursor?: string) => `{
      trendingFeed(tag: "top40" , input: {limit: ${limit}, ${
  cursor !== undefined ? `cursor: ${cursor}` : ""
}}) {
        songs ${getFullSongQuery()}
        next
      }
    }`;

export const chartByTagQuery = (
  tag: string,
  limit: number,
  cursor?: string,
) => `
    query {
        chartByTag(${
          tag && tag !== "topSongs" ? `tag: "${tag}",` : `tag: "",`
        } ${generateInput(limit, cursor)}) {
           songs ${getFullSongQuery()}
          next
        }
    }
`;

export const userSongsQuery = (
  userID: string,
  songsLimit: number,
  cursor: string | undefined,
) => `
    query {
        user(id: "${userID}") {
            songs(${generateInput(songsLimit, cursor)}) {
            songs ${getFullSongQuery()}
            next
            }
        }
    }`;

export const userVotesQuery = (
  userID: string,
  votesLimit: number,
  cursor: string | undefined,
) => `
query {
  user(id: "${userID}") {
    id
    votes(${generateInput(votesLimit, cursor)}) {
      votes {
        song ${getFullSongQuery()}
      }
      next
    }
  }
}`;

export const mutualDiscoveriesQuery = (
  friendsList: string[],
  limit: number,
  cursor: string | undefined,
) => `
query {
  mutualDiscoveries(friendIDs: ${JSON.stringify(friendsList)}, ${generateInput(
  limit,
  cursor,
)}) {
      songs ${getFullSongQuery()}
    next
  }
}`;

export const communityFavoritesQuery = (
  friendsList: string[],
  limit: number,
  cursor: string | undefined,
) => `
query {
  communityFavorites(friendIDs: ${JSON.stringify(friendsList)}, ${generateInput(
  limit,
  cursor,
)}) {
      songs ${getFullSongQuery()}
    next
  }
}`;

export const recommendFeedQuery = () => `
    query {
        recommend ${getFullSongQuery()}
    }
`;

export const userBySnapIDQuery = (snapID: string) => `
query {
  userBySnapID(snapID: "${snapID}") 
    ${fullUserQuery}
}`;

export const getSnapUserQuery = (userID: string, isMyUser?: boolean) => {
  return `query {
         user${!isMyUser ? `(id: "${userID}")` : ""} ${
    isMyUser ? fullMyUserQuery : fullUserQuery
  }
    }
    `;
};

export const updateSnapUserMutation = (snapID: string) => {
  return `mutation {
        updateUser(input: {snapID: "${snapID}"}) ${basicMutationResponse}
    }`;
};

export const basicMutationResponse = `
{
    code
    success
    message
}
`;

export const featuredTagsQuery = (limit: number, cursor?: string) => `
query {
  featuredTags(${generateInput(limit, cursor)}) {
    tags
    next
  }
}`;
