import { DISCOVER_FEED_ID } from "../../constants";

import { loadPlaylist } from "./navigationFetchFunctions";

let isLoadingFeed = false;
export async function loadFeed(playlistID?: string) {
  playlistID = playlistID ?? DISCOVER_FEED_ID;
  if (isLoadingFeed) return null;
  isLoadingFeed = true;
  //TODO: switch this to mini-specific query
  // console.re.log("loadFeed", playlistID);
  const status = await loadPlaylist(
    playlistID,
    playlistID === DISCOVER_FEED_ID ? 10 : 4,
  );
  // console.re.log("loadFeed status", playlistID, status);
  // const fetchedFeed = await fetchFeed(plalyistID);
  // const { songs, users } = extractUsersFromSongs(fetchedFeed?.songs);
  // if (!songs?.length) return;
  // pushSongs(songs, users, plalyistID ?? "discover");
  isLoadingFeed = false;
  return status;
}
