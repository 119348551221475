import toast from "react-hot-toast";
import { darkerGreyBackground } from "./colors";
import { sfProTextRegular } from "./fonts";

export function showToast(message: string, icon: string) {
  //See how to use: https://react-hot-toast.com/
  toast.success(message, {
    icon: icon || undefined,
    style: {
      marginTop: 15,
      borderRadius: "10px",
      background: darkerGreyBackground,
      color: "#fff",
      fontFamily: sfProTextRegular,
    },
    duration: 800,
  });
}
