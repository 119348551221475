import { AnimationItem } from "lottie-web";
import { CSSProperties, useRef } from "react";
import ClickableOpacityDiv from "../../../../../../../../../components/clickableOpacityDiv/ClickableOpacityDiv";
import Burst from "../../../../../../../../../assets/lotties/burst/Burst";
// import AnimationDirection from "../../../../../../../../../../node_modules/lottie-web/index";
import { toggleIsBurstMode } from "../../../../../../../../../../src/controlModule/trackController";
import { useSelector } from "react-redux";
import { scale } from "../../../../../../../../../utilities/scaling";
import { logEvent } from "../../../../../../../../../utilities/statsig";

type Props = {
  height?: number;
  style?: CSSProperties;
};

const BURST_SWITCH_HEIGHT = 28;

export default function BurstSwitch({ height, style }: Props) {
  const resolvedHeight = height ?? BURST_SWITCH_HEIGHT;
  const isBurstMode: boolean = useSelector(
    (state: AppState) => state.content.isBurstMode,
  );
  const animationRef = useRef<AnimationItem | undefined>();

  function toggleBurst() {
    logEvent("SWITCH FROM", isBurstMode ? "BURST" : "SONG", {
      mode: isBurstMode ? "BURST" : "SONG",
    });
    toggleIsBurstMode();
    animationRef.current?.setDirection(isBurstMode ? 1 : -1);
    animationRef.current?.play();
  }

  return (
    <ClickableOpacityDiv
      withOpacity
      onClick={toggleBurst}
      style={{
        ...{ borderRadius: resolvedHeight / 2, overflow: "hidden" },
        ...style,
      }}
    >
      <Burst animationRef={animationRef} height={resolvedHeight} />
    </ClickableOpacityDiv>
  );
}
