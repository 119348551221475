import { useSelector } from "react-redux";

export default function useActiveSong() {
  const activeSong = useSelector(
    (state: AppState) =>
      state.content.currSong ??
      state.content.songMap[
        state.content.playlists[state.content.currPlaylist]?.[
          state.content.currIndex
        ] ?? ""
      ],
  );
  return { activeSong };
}
