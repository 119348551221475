import {
  getAuth,
  signInWithEmailAndPassword,
  UserCredential,
  User,
  createUserWithEmailAndPassword,
} from "@firebase/auth";
import { firebaseApp } from "..";
import { hardcodeIsFirstTimeUser } from "../constants";

let isFirstTimeUser: boolean;

export function getIsFirstTimeUser() {
  return hardcodeIsFirstTimeUser || isFirstTimeUser;
}

async function getIdToken(): Promise<string> {
  const cu = getCurrentUser();
  if (cu) return cu.getIdToken();
  throw "current user null";
}

function getCurrentUser(): User | null {
  return getAuth(firebaseApp).currentUser;
}

const loginUser = async (id: string): Promise<UserCredential> => {
  return signInWithEmailAndPassword(
    getAuth(firebaseApp),
    `${id}@fwaygoapp.com`,
    id,
  ).then((user: UserCredential) => {
    return user;
  });
};

const signupUser = async (id: string): Promise<UserCredential> => {
  return createUserWithEmailAndPassword(
    getAuth(firebaseApp),
    `${id}@fwaygoapp.com`,
    id,
  ).then((user: UserCredential) => {
    return user;
  });
};

const authUser = async (userID: string): Promise<UserCredential> => {
  //console.re.log("[authUser] sc userID", userID);
  return loginUser(userID)
    .then((user) => {
      isFirstTimeUser = false;
      return user;
    })
    .catch(async (err) => {
      if (err.code === "auth/user-not-found") {
        return signupUser(userID)
          .then((user) => {
            isFirstTimeUser = true;
            return user;
          })
          .catch((err) => {
            throw err;
          });
      }
      throw err;
    });
};

export { getIdToken, getCurrentUser, loginUser, signupUser, authUser };
