const azonix = "Azonix";

const sfProTextRegular = "SFProTextRegular";
const sfProTextSemibold = "SFProTextSemibold";
const sfProTextBold = "SFProTextBold";

const sfMonoRegular = "SFMonoRegular";
const sfMonoSemibold = "SFMonoSemibold";

const gothamBlack = "GothamBlack";

export {
  azonix,
  sfProTextRegular,
  sfProTextSemibold,
  sfProTextBold,
  sfMonoRegular,
  sfMonoSemibold,
  gothamBlack,
};
