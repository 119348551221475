import { songVoteDataFields } from "./queries";

export const voteMutation = (songID: string, type: VoteState) => `
mutation {
  vote(id: "${songID}", type: ${type}) {
    code
    success
    message
    vote {
      type
      song {${songVoteDataFields()}}
      voter {
        score
        id
      }
    }
  }
}`;

export const streamAddMutation = (
  id: string,
  timestamp: Date,
  duration: number,
) => `
mutation  {
  streamAdd(input: {
    id: "${id}"
    timestamp: ${timestamp.getTime()}
    duration: ${duration}
  }) {
    code
    success
    message
  }
}
`;

export const setIsExplicitMutation = (songID: string, isExplicit: boolean) => `
mutation  {
  ADMIN_SONGS_SET_IS_EXPLICIT(songID: "${songID}", isExplicit: ${isExplicit}) {
    code
    success
    message
  }
}`;
