import { useLayoutEffect, useRef, useState } from "react";
import { getDims } from "../../utilities/scaling";
import "./marqueeSpanAnimations.scss";

const { width } = getDims();

export type Props = {
  marqueeId: string;
};

export default function MarqueeSpan(props: SpanProps) {
  const [animationDutation, setAnimationDuration] = useState<number | null>(
    null,
  );
  const animationDutationRef = useRef<number | null>(null);
  const animatedMarqueeSpanRef = useRef<HTMLSpanElement | null>(null);
  const marqueeSpanRef = useRef<HTMLSpanElement | null>(null);
  const { style, children, marqueeId, ...spanProps } = props;

  useLayoutEffect(() => {
    if (!marqueeSpanRef.current || !animatedMarqueeSpanRef.current) return;
    animatedMarqueeSpanRef.current.style.animation = "none";
    // marqueeSpanRef.current.style.animation = "none";
    const { offsetWidth, scrollWidth } = marqueeSpanRef.current;
    const duration =
      scrollWidth > offsetWidth
        ? (scrollWidth + (animationDutationRef.current ? 0 : width * 0.25)) * 26
        : null;
    animatedMarqueeSpanRef.current.style.animation = duration
      ? `marquee-span ${animationDutation}ms linear infinite`
      : "none";
    animationDutationRef.current = duration;
    setAnimationDuration(duration);
  }, [marqueeId]);

  return (
    <span
      ref={marqueeSpanRef}
      style={{
        ...{
          textOverflow: "none",
          overflow: undefined,
        },
        ...style,
      }}
      {...spanProps}
    >
      <span
        ref={animatedMarqueeSpanRef}
        style={{
          animation: animationDutation
            ? `marquee-span ${animationDutation}ms linear infinite`
            : "none",
          display: "inline-block",
          paddingRight: animationDutation ? width * 0.25 : undefined,
        }}
      >
        {children}
        {!!animationDutation && (
          <span
            style={{
              position: "absolute",
              height: "100%",
              left: "100%",
              display: "inline-block",
            }}
          >
            {children}
          </span>
        )}
      </span>
    </span>
  );
}
